
/* eslint-disable */
import * as actionTypes from '../constant';

const INITIAL_STATE = {}

export const reportReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.INVENTORY_REPORT:
            return {
                ...state,
                inventoryReport: action.value
            };

        case actionTypes.WASTAGE_REPORT:
            return {
                ...state,
                wastageReport: action.value,
            };
        case actionTypes.SALES_REPORT:
            return {
                ...state,
                salesReport: action.value,
            };
        case actionTypes.LOG_ERROR:
            return {
                ...state,
                reportError: action.value,
            };
        case actionTypes.REGISTRATION_REPORT:
            return {
                ...state,
                registration_report: action.value,
            };
        case actionTypes.APP_OPEN_REPORT:
            return {
                ...state,
                APP_OPEN_REPORT: action.value,
            };
            case actionTypes.WALK_STAT_REPORT:
            return {
                ...state,
                WALK_STAT_REPORT: action.value,
            };
        default:
            return { ...state, reportError: '' };
    }
}