import React from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import { petActivity } from '../services/PetServices'
import ActivityList from '../components/ActivityList';
import PetView from '../components/PetView';
import ViewUser from '../components/ViewUser'
import PetUpdate from '../components/PetUpdate';
import PetHoc from '../Hoc/PetHoc'


class Activity extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activityList: [],
            activityLoading: false,
            hideShow: false,
            showUser:false,
            showPetUpdate: false,

        }
    }
    toggleUserButton =( userEmail = '') =>{
        this.setState({           
            userEmail: userEmail,
            showUser: !this.state.showUser,
        });
    }
    togglePetButton = (PetId = '') => {
        this.setState({
            hideShow: !this.state.hideShow,
            showPetUpdate: false,
            PetId: PetId,
            showUser:false
        });
    }

    componentDidMount() {
        document.title = "Activity List"
        this.setState({ activityLoading: true }, () => {
            petActivity()
                .then(res => {
                    if (res.data !== undefined && res.data !== '') {
                        this.setState({
                            activityList: res.data.petActivityList,
                            activityLoading: false
                        })
                    }

                })
                .catch(err => {
                    console.log(err.data)
                    this.setState({ activityLoading: false })
                })
        })

    }
    render() {

        return (
            <div className="container-fluid">
                <Row>
                    <Col md="12">
                        <Card className="card-box">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="title">Activity List</div>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        {this.state.hideShow && <React.Fragment>
                                            <button className="btn-primary-fill  mt-3"
                                                onClick={() => this.togglePetButton()}> {'Activity List'}</button>
                                        </React.Fragment>}
                                        { this.state.showUser ? <React.Fragment>
                                            <button className="btn-primary-fill  mt-3"
                                                onClick={() => this.toggleUserButton()}> {'Activity List'}</button>
                                        </React.Fragment>: null }
                                    </div>
                                </div>
                            </div>
                            <CardBody>                                
                                {this.state.hideShow ? this.state.showPetUpdate ?
                                    <PetUpdate petId={this.state.PetId}
                                    togglePetButton={() => this.togglePetButton()}
                                        {...this.props} /> :                                   
                                    <PetView petId={this.state.PetId}
                                    togglePetButton={() => this.togglePetButton()}
                                        {...this.props} /> :
                                    this.state.activityLoading ? <i className="fa fa-spinner fa-pulse fa-3x fa-fw text-center"></i> :
                                    (
                                        this.state.showUser ?                                
                                    <ViewUser toggleUserButton={() => this.toggleUserButton()} hideEditbutton={true}  userEmail={this.state.userEmail}  {...this.props} />:
                                        
                                    this.state.activityList.length > 0 && <ActivityList toggleUserButton={this.toggleUserButton} togglePetButton={this.togglePetButton} activityList={this.state.activityList} {...this.props} />
                                    )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default PetHoc(Activity)