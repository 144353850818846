import React from 'react';
import './App.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Routes from './routes'
import { Provider } from 'react-redux';
import { persistor,store } from './redux/store'
import { PersistGate } from 'redux-persist/lib/integration/react';




class App extends React.Component{

	render(){
	    return(
			 <Provider store={store}>
				 <PersistGate loading={<LoadingView />} persistor={persistor}>
					 <Routes/>
				</PersistGate>
			</Provider>
	    	
	    )
	}
}
const LoadingView=()=>{
	return(
		<h2 style={{textAlign:"center"}}>Loading</h2>	
	)
}
export default App;
