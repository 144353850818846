
/* eslint-disable */
import * as actionTypes from '../constant';
import { login,userForgotPassword,sendNewPassword } from '../../services/authFunctions';

export const loginUser = (values) => {
	
	return dispatch => {
		login(values).then(res => {
			
			if(res.username!==undefined && res.signInUserSession!==undefined){
						localStorage.setItem('sessionToken', res.signInUserSession.accessToken.jwtToken);
						localStorage.setItem('token', res.signInUserSession.idToken.jwtToken);
						dispatch(success(res.attributes));
			}else{
				dispatch(error(res.data.message));
			}

		}).catch(err => {
			dispatch(error(err.message));
		});
	}
}

export const forgotPassword = (values) => {
	
	return dispatch => {
		userForgotPassword(values).then(res => {
			dispatch({
				type: actionTypes.FORGOT_PASSWORD,
				value: values.email
			})

		}).catch(err => {
			dispatch(error(err.message));
		});
	}
}

export const setNewPassword = (values) => {
	return dispatch => {
		sendNewPassword(values).then(res => {
			
			dispatch({
				type: actionTypes.SET_PASSWORD,
				value: true
			})

		}).catch(err => {
			dispatch(error(err.message));
		});
	}
}


const success = (res) => {
	return {
		type: actionTypes.LOGIN,
		value: res.data
	}
}


const error = (err) => {
	return {
		type: actionTypes.LOG_ERROR,
		value: err
	}
}


