import * as actionTypes from '../constant';
import { pets, createOrder, updateOrder, updateOrderStatus, deleteOrder,updatePet } from '../../services/PetServices';

export const petListAction = () => {

    return dispatch => {
       pets().then(res => {
            if (res.data !== undefined) {
                dispatch(getPetList(res.data));
                dispatch(clearError());

            }
        }).catch(err => {
            console.log('error order list action ', err);
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}
export const petUpdateAction = (petId,data) => {

    return dispatch => {
        updatePet(petId,data).then(res => {
            if (res.data !== undefined) {
                dispatch( {
                    type: actionTypes.PET_UPDATE,
                    value: res.data
                });
               

            }
        }).catch(err => {
            console.log('error order Update action ', err);
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const getPetList = (res) => {
    return {
        type: actionTypes.PET_LIST,
        value: res
    }
}

const error = (err) => {
    return {
        type: actionTypes.LOG_ERROR,
        value: err
    }
}
const clearError = (err) => {
    return {
        type: actionTypes.CLEAR_ERROR,
    }
}


export const createOrderAction = (order) => {
    return dispatch => {
        createOrder(order).then(res => {
            if (res.data !== undefined) {
                dispatch(createOrderPost(res.data.data));
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error order create action ', err);
            //var errMsg = Object.assign({}, err).response.data.error_description;
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const createOrderPost = (res) => {
    return {
        type: actionTypes.ORDER_CREATE,
        value: res
    }
}


export const updateOrderAction = (orderId, order) => {
    return dispatch => {
        updateOrder(orderId, order).then(res => {
            if (res.data !== undefined) {
                dispatch(updateOrderPut(res.data.data));
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error order update action ', err);
            //var errMsg = Object.assign({}, err).response.data.error_description;
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const updateOrderPut = (res) => {
    return {
        type: actionTypes.ORDER_UPDATE,
        value: res
    }
}

export const updateOrderStatusAction = (orderId, orderStatus) => {
    return dispatch => {
        updateOrderStatus(orderId, orderStatus).then(res => {
            if (res.data !== undefined) {
                dispatch(updateOrderStatusPut(res.data.data));
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error order update status action ', err);
            //var errMsg = Object.assign({}, err).response.data.error_description;
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const updateOrderStatusPut = (res) => {
    return {
        type: actionTypes.ORDER_STATUS,
        value: res
    }
}


export const deleteOrderAction = (orderId) => {
    return dispatch => {
        deleteOrder(orderId).then(res => {
            if (res.data !== undefined) {
                dispatch(deleteOrderDelete(res.data.data));
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error order update status action ', err);
            //var errMsg = Object.assign({}, err).response.data.error_description;
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const deleteOrderDelete = (res) => {
    return {
        type: actionTypes.ORDER_DELETE,
        value: res
    }
}