
/* eslint-disable */
import React from 'react'
import { Row, Col } from 'reactstrap'
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { ModalPopup } from '../helper/ModalPopup'
import { petActivityDetails } from '../services/PetServices'


class ActivityList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activityList: props.activityList,
            activityModal: false,
            activityDetail: {},
            activityModalLoading: false
        }
    }
    toggleActivityModal = () => {
        this.setState({
            activityModal: false,
            activityDetail: {}
        })
    }
    showActivityDetails = (row) => {
        this.setState({ activityModalLoading: true, activityModal: true }, () => {
            petActivityDetails(row.petActivityId).then(res => {
                if (res.data !== undefined && res.data !== '') {
                    this.setState({
                        activityDetail: res.data,
                        activityModalLoading: false
                    })
                }
            }).catch(err => console.log(err.data))
        })
    }
    showUserDetails = (row) => {
       console.log("clicked",row)
		this.props.toggleUserButton(row.userEmail);

    }
    showPetDetails = (row) => {
        console.log("clicked",row)        
		this.props.togglePetButton(row.petId);
     }
    activityActionFormatter = (cell, row) => {
        return <button className="btn btn-primary" onClick={() => this.showActivityDetails(row)}><i className="fa fa-eye text-center"></i></button>
    }
    activityusernameFormatter = (cell, row) => {
    return <p className="clickabletag"  onClick={() => this.showUserDetails(row)}>{row.userName}</p>
    }
    activitypetnameFormatter = (cell, row) => {
        return <p className="clickabletag"  onClick={() => this.showPetDetails(row)}>{row.petName}</p>
        }
    render() {

        let activityColumn = [
            { dataField: "petActivityId", text: "Pet Activity Id", headerAlign: 'left', align: 'left', sort: true, headerStyle: () => { return { width: '160px' }; } },
            { dataField: "userName", text: "User Name", headerAlign: 'left', align: 'left', sort: true,formatter: this.activityusernameFormatter},
            { dataField: "petName", text: "Pet Name", headerAlign: 'left', align: 'left', sort: true,formatter: this.activitypetnameFormatter},
            { dataField: "activityCreationDate", text: "Created At", headerAlign: 'left', align: 'left', sort: true, formatter: (cell) => cell !== '' && new Date(cell).toLocaleDateString() },
            { dataField: "totalMile", text: "Total Mile", headerAlign: 'center', align: 'center', sort: true, formatter: (cell) => cell !== '' && parseFloat(cell).toFixed(2) },
            { dataField: "totalTime", text: "Total time", headerAlign: 'left', align: 'left', sort: true },
            { dataField: "totalEarnedPoints", text: "Total Earned Points", headerAlign: 'left', align: 'left', sort: true },
            { dataField: "", text: "Action", headerAlign: 'left', align: 'left', formatter: this.activityActionFormatter, headerStyle: () => { return { width: '100px' }; } },
        ]
        const { SearchBar } = Search;
        return (
            <React.Fragment>
                <ToolkitProvider
                    keyField="petActivityId"
                    key={`PetActvitylist`}
                    data={this.state.activityList}
                    columns={activityColumn}
                    search
                >
                    {
                        props => (
                            <div>
                                {/* <div className="mb-2">
											<SearchBar {...props.searchProps} className="offset-md-9 col-md-3" />
										</div> */}
                                <div className="table-responsive">
                                    <BootstrapTable {...props.baseProps} pagination={paginationFactory()} noDataIndication="No results!" />
                                </div>
                            </div>
                        )
                    }
                </ToolkitProvider>
                {/* <BootstrapTable
                        keyField="petActivityId"
                        key='PetActvitylist'
                        data={this.state.activityList}
                        columns={activityColumn}
                        noDataIndication="No results!"
                        pagination={paginationFactory() } 
                        
                /> */}
                {/* Modal poprup */}

                <ModalPopup modal={this.state.activityModal} toggle={this.toggleActivityModal}>
                    {this.state.activityModalLoading ? <Row className="text text-center"> <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                    </Row> : <React.Fragment>
                            <h5 className="milstone-heading">Earned Points & Miles</h5>
                            <Row>
                                <Col lg={6}>
                                    <div className="card text-center mb-4">
                                        <div className="card-body">
                                            <h6>Total Earned Points </h6>
                                            <h3>{this.state.activityDetail.totalEarnedPoints !== undefined && this.state.activityDetail.totalEarnedPoints}</h3>
                                            
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="card text-center mb-4">
                                        <div className="card-body">
                                        <h6>Total Miles </h6>     
                                        <h3>{this.state.activityDetail.totalMile !== undefined && parseFloat(this.state.activityDetail.totalMile).toFixed(2)}</h3>                                       
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <div className="activity-table">
                                <h5 className="milstone-heading">Milestones</h5>
                                {this.state.activityDetail.milestones !== undefined && <BootstrapTable
                                    keyField="afterWalkedMiles"
                                    key='PetActvityDetails'
                                    data={this.state.activityDetail.milestones}
                                    columns={[
                                        { dataField: "afterWalkedMiles", text: "Walked Miles", headerAlign: 'left', align: 'left' },
                                        { dataField: "pointCollected", text: "Points Collected", headerAlign: 'left', align: 'left' },
                                    ]}
                                    noDataIndication="No results!"
                                    pagination={paginationFactory()}
                                />}
                                </div>
                            </Row>
                        </React.Fragment>}
                </ModalPopup>

            </React.Fragment>
        )
    }
}
export default ActivityList