import Api from '../Api'

export const pageList = () => {
    return Api.get('user-service/v1/template/templates');
}

export const addPage = (data) => {
    return Api.post('user-service/v1/template',data);
}
export const updatePage = (data,type) => {
    return Api.put('user-service/v1/template?type='+type,data);
}

