
/* eslint-disable */
import React, { Component } from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from "react-bootstrap-table-next"
import { Row, Col, Label,Button } from 'reactstrap'
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getTransactionHistory } from '../services/UserServices'


class ViewUser extends Component {
	constructor(props) {
		super(props);

		this.state = {
			packageVisibility: false,
			statementLoading: false,
			packages: [],
			statement: [],
			oldStatement: [],
			errors:[],
		}
	}
	handleFilter=(filter)=>{
		let oldHistory=[...this.state.oldStatement]
		let filterHistory= oldHistory.filter(data=>data.source===filter)
		this.setState({
			statement:filterHistory,
			sourceFilter:filter
		})
	}
	txnHistory = () => {
		this.setState({statementLoading:true},()=>{

		
			getTransactionHistory(this.props.userEmail).then(res => {
				
				if (res.data !== undefined) {
					this.setState({
						statementLoading: false,
						statement: res.data.history,
						oldStatement: res.data.history

					});
				}
			}).catch(err => {
				this.setState({
					statementLoading: false,
					errors:err.data.errors
				})
			})
		})
	}
	

	render() {	
		let user = (this.props.allUsers.length) ? this.props.allUsers.find(user => user.email === this.props.userEmail) : {};
		const defaultSorted = [{dataField: 'date',order: 'desc'}];
		this.columns = [
			{ dataField: "petId", text: "Pet Id" },
			{ dataField: "source", text: "Source" },
			{ dataField: "type", text: "Type" },
			{ dataField: "point", text: "Point" },
			{ dataField: "date", text: "Created At",formatter:(cell)=>cell!==undefined && cell!=='' && new Date(cell).toLocaleDateString() },
		];
		
		return (
			<React.Fragment>
				{this.state.errors.length>0 && this.state.errors.map(err=> <div key={err.code} className="alert alert-danger">{err.message}</div>)}
				<h5><strong>{user.firstName!==undefined?user.firstName:''+' '+user.lastName!==undefined?user.lastName:''}</strong></h5>
				<h6>{this.props.t('Email')} : {user.email}</h6>
				{user.phoneNumber!==undefined &&<h6>Phone No. : {user.phoneNumber}</h6>}
				{user.address!==undefined &&<h6>Address. : {user.address}</h6>}
				{user.birthDate!==undefined &&<h6>Birth Date. : {user.birthDate}</h6>}
				{user.gender!==undefined &&<h6>Gender. : {user.gender}</h6>}
				{!this.props.hideEditbutton ? <button className="btn-primary-fill mt-2" onClick={() => this.props.updateLayout('Edit User', this.props.userEmail)} >{this.props.t('Edit')}</button> : null }
				
				{/*<button className="btn-primary-fill ml-2" onClick={this.toggleVisibility}>{this.state.visibility ? this.props.t('HideStock') : this.props.t('ViewStock')}</button>*/}
				<button className="btn-primary-fill ml-2" onClick={this.txnHistory}>Transaction History</button>

				
				{ this.state.statementLoading?<Row className="text-center"> <i className="fa fa-spinner fa-pulse fa-3x fa-fw text-center"></i>
                </Row>: <div id="accordionStatement" className="mt-4 accordionStatement">
							
						{this.state.statement.length ?<div> 
							<div className="form-group col-md-3"> 
								<label>Filter By</label>
								<select name="sourceFilter" className="form-control" defaultValue={this.state.sourceFilter} onChange={(e)=>this.handleFilter(e.target.value)}>
									<option value="">---Select--</option>
									<option value="Activity">Activity</option>
									<option value="Level Up">Level Up</option>
									<option value="Milestone Completion">Milestone Completion</option>
								</select>
							</div>
							<BootstrapTable 
								key='petId' 
								keyField='date' 
								data={this.state.statement}
								columns={this.columns} 
								noDataIndication="No results!"
								defaultSorted ={defaultSorted}
								pagination={paginationFactory()}
							/>
							</div>
							:''}

					</div>

				}
			</React.Fragment>
		)
	}
}

export default ViewUser