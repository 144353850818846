
/* eslint-disable */
import React, {Component, useState} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter,Button } from 'reactstrap';

export const ModalPopup = (props) => {
	const { modal } = props;

	const { toggle } = props;

	return (
		<div>
			<Modal isOpen={modal} toggle={toggle} size="md">
                <ModalHeader toggle={toggle}>{props.title}</ModalHeader>
				<ModalBody>
                    {props.children}
				</ModalBody>
				<ModalFooter>
					<Button className="btn-primary-fill" onClick={toggle}>Ok</Button>{' '}
					{/* <Button color="secondary" onClick={toggle}>Cancel</Button> */}
				</ModalFooter>
			</Modal>
		</div>
	);
}