import React, { Component } from 'react'
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap'
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import i18n from '../i18n';
import thumbnail from '../assets/images/thumbnail.png'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { AlertMessage } from '../helper/AlertBox'


const onColumnMatch = ({ searchText, value, column, row }) => {
    if (searchText !== '' || searchText !== null) {
        return row.petName.trim().toLowerCase().indexOf(searchText) < 0 ? false : row
    }
    return row
}


class PetList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            allCustomers: props.allCustomers,
            lookupData: props.lookupData,
            allPets: props.allPets,
            lng: 'en',
            success: props.success,
            error: props.error,
            show: false,
            orderId: '',
            orderStatus: ''
        }
    }


    UNSAFE_componentWillReceiveProps(nextProps) {

        this.setState({
            allCustomers: nextProps.allCustomers,
            lookupData: nextProps.lookupData,
            allPets: nextProps.allPets,
            success: nextProps.success,
            show: nextProps.success ? false : this.state.show,
        })

    }

    updateView = (orderId) => {
        // console.log()
        this.props.toggleButton(orderId);
    }


    updateOrderStatus = (orderId, orderStatus) => {
        this.props.updateOrderStatus(orderId, { orderStatus: orderStatus });
    }

    deleteOrder = (orderId) => {
        console.log('delete order', orderId);
        this.props.deleteOrder(orderId);

    }

    imageThumbFormatter = (cell, row) => {
        return (<span><img alt="order" src={cell ? cell : thumbnail} width={50} /></span>);
    }


    actionFormatter = (cell, row) => {

        return (
            <span>
                <button className="btn btn-link" onClick={() => this.updateView(row.id)}><i className="fa fa-eye"></i> </button>
            </span>
        )
    }

    handleClose = () => this.setState({ orderId: '', orderStatus: '', show: !this.state.show });

    handleShow = (orderId, orderStatus) => {
        this.setState({ orderId: orderId, orderStatus: orderStatus, show: true });
    }
    
    render() {

        let langId = i18n.language === 'en' ? 1 : 2
        let statusOptions = this.state.lookupData !== undefined && this.state.lookupData.Status.map(
            (status) => { return <option key={`statusOpt${status.statusId}`} value={status.statusId}>{status.text.find(text => text.langId === langId).name}</option> }
        )
        document.title = 'Pet List';

        const { SearchBar } = Search;
        const defaultSorted = [{
            dataField: 'id',
            order: 'desc'
        }];
        let columns = [
            { dataField: "id", text: 'ID', headerAlign: 'left', align: 'left', sort: true },
            { dataField: "petName", text: "Pet Name", headerAlign: 'left', align: 'left', sort: true },
            { dataField: "profileImageName", text: "Image", formatter: this.imageThumbFormatter, headerAlign: 'center', align: 'center' },
            { dataField: "ownerName", text: "Owner", headerAlign: 'left', align: 'left',sort: true },
            { dataField: "", text: "Action", formatter: this.actionFormatter, headerAlign: 'center', align: 'center',headerStyle: () => {return { width: '100px' };}}
        ];


        return (

            <React.Fragment>
                <Row>
                    {this.state.success !== '' && this.state.success !== undefined ? <Col lg="12" md="12"><AlertMessage success={this.state.success} /></Col> : ''}
                </Row>
                <Row>
                    <Col lg="12" md="12">
                        {this.state.allPets !== undefined ?
                            <ToolkitProvider
                                keyField="id"
                                key='Petlist'
                                data={this.state.allPets}
                                columns={columns}
                                search={{ onColumnMatch }}
                            >
                                {
                                    props => (
                                        <div>
                                            <div className="mb-2">
                                                <SearchBar {...props.searchProps} placeholder={this.props.t('Search')} className="offset-md-9 col-md-3" />

                                            </div>
                                            <div className="table-responsive">
                                                <BootstrapTable
                                                    {...props.baseProps}
                                                    pagination={paginationFactory()}
                                                    defaultSorted={defaultSorted}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            </ToolkitProvider>
                            : ''}
                    </Col>
                </Row>

                <Modal isOpen={this.state.show}>
                    <ModalHeader>
                        Update Order Status
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="4" className="align-items-center">Order#:</Col>
                            <Col md="8">{this.state.orderId}</Col>
                        </Row>
                        <Row className={`form-group`}>
                            <label className="col-md-4">{this.props.t('Status')}:</label>
                            <Col md="8" className="align-items-center">
                                <Input
                                    type="select"
                                    name="orderStatus"
                                    value={this.state.orderStatus}
                                    onChange={(e) => this.setState({ 'orderStatus': e.target.value })}
                                    className="form-control">
                                    <option value="">-- {this.props.t('Status')} --</option>
                                    {statusOptions}
                                </Input>
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={this.handleClose}>Close</Button>
                        <Button color="primary" className="btn btn-primary" onClick={() => this.updateOrderStatus(this.state.orderId, this.state.orderStatus)}>Update</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}

export default PetList;