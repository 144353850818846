
/* eslint-disable */
import * as actionTypes from '../constant';
import {roleToRedirect} from '../../helper/General'

const INITIAL_STATE = {};

export const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOGIN:
      return {
        ...state,
        loggedIn: true,
        userProfile :action.value,
        redirectTo:roleToRedirect(1)//action.value.roleId
      };

    case actionTypes.LOGOUT:
      return {
        ...state,
        loggedIn: false,

      };
    case actionTypes.LOG_ERROR:
      return {
        ...state,
        loggedIn: false,
        loginError: action.value
      };
    case actionTypes.FORGOT_PASSWORD:
      return {
        ...state,
        loggedIn: false,
        setPasswordForm:true,
        email:action.value,
        loginError:'',
      };
    case actionTypes.SET_PASSWORD:
      return {
        ...state,
        loggedIn: false,
        setPasswordForm: false,
        loginError:'',
      };
    case actionTypes.CLEAR_ERROR:
      return {
        ...state,
        loginError: null
      };


    default:
      return state; 
  }
}
