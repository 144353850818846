import Api from '../Api'

export const customers = () => {
    return Api.get('customer').then(res => res.data).catch(err => console.log(err))
};

export const customerStatement = (customerId,filter) => {
    return Api.get('customer/'+customerId+'/transactions?fromDate='+filter.fromDate+'&endDate='+filter.endDate);
}

export const createCustomer = (customer) => {
    return Api.post('customer', customer);
}

export const updateCustomer = (customerId, customer) => {
    return Api.put('customer/'+customerId, customer);
}

