

/* eslint-disable */
import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { createBranchAction, updateBranchAction } from '../redux/actions/BranchActions';
import { branchValidation } from '../Validation/BranchValidationSchema';
import { AlertMessage } from '../helper/AlertBox';

class BranchAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            branch: {},
            allBranches: this.props.allBranches,
            error: '',
        }
    }

    static getDerivedStateFromProps(nextProps, state) {
        /*let branch = nextProps.allBranches !== undefined && nextProps.allBranches.find(branch => {
                return branch.branchId === nextProps.branchId
            });
        if (nextProps.branchId!==undefined && nextProps.branchId !== "" && Object.entries(state.branch).length === 0) {
            return {
                ...state,
                allBranches: nextProps.allBranches,
                branch
            }
        }*/
        if (nextProps.branch !== undefined && state.branch !== nextProps.branch) {
            nextProps.updateLayout('Branch List');
        }
        return null;
    }

    render() {
        let branch = this.state.allBranches !== undefined && this.state.allBranches.find(branch => {
            return branch.branchId === this.props.branchId
        });
        return (
            <React.Fragment>
                <Formik
                    initialValues={{
                        branchNameEng: branch !== undefined && branch.branchName !== undefined ? branch.branchName.find(branchRow => branchRow.langId === 1).name : '',
                        branchNameThi: branch !== undefined && branch.branchName !== undefined ? branch.branchName.find(branchRow => branchRow.langId == 2).name : '',
                        branchAddress: branch !== undefined && branch.branchAddress !== undefined ? branch.branchAddress : '',
                        branchPhone: branch !== undefined && branch.branchPhone !== undefined ? branch.branchPhone : '',
                    }}
                    onSubmit={values => {
                        let branchData = {
                            branchName: [
                                { langId: 1, name: values.branchNameEng },
                                { langId: 2, name: values.branchNameThi },
                            ],
                            branchAddress: values.branchAddress,
                            branchPhone: values.branchPhone
                        };
                        if (this.props.branchId === "" || this.props.branchId === undefined) {
                            console.log("values to create branch ", values);
                            this.props.createBranch(branchData);
                        }
                        else {
                            console.log("Values to edit branch ", branchData);
                            this.props.updateBranch(this.props.branchId, branchData);
                        }
                    }}
                    validationSchema={Yup.object().shape({ ...branchValidation })
                    }
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset
                        } = props;
                        return (

                            <form onSubmit={handleSubmit} >
                                <Row>
                                    {this.state.error !== '' ? <Col lg="12" md="12"><AlertMessage key={new Date().getTime()} error={this.state.error} /></Col> : ''}
                                </Row>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <label className="col-md-3">{this.props.t("Branch Name")}(En)</label>
                                            <div className="col-md-9">
                                                <input type="text"
                                                    name="branchNameEng"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    value={values.branchNameEng} />
                                                {errors.branchNameEng && touched.branchNameEng && (<div className="input-feedback">{errors.branchNameEng}</div>)}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <label className="col-md-3">{this.props.t("Branch Name")}(Th)</label>
                                            <div className="col-md-9">
                                                <input type="text"
                                                    name="branchNameThi"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    value={values.branchNameThi} />
                                                {errors.branchNameThi && touched.branchNameThi && (<div className="input-feedback">{errors.branchNameThi}</div>)}
                                            </div>
                                        </div>
                                    </div>
                                </div><div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <label className="col-md-3">{this.props.t("Branch Address")}</label>
                                            <div className="col-md-9">
                                                <input type="text"
                                                    name="branchAddress"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    value={values.branchAddress} />
                                                {errors.branchAddress && touched.branchAddress && (<div className="input-feedback">{errors.branchAddress}</div>)}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <label className="col-md-3">{this.props.t("Phone")}</label>
                                            <div className="col-md-9">
                                                <input type="text"
                                                    name="branchPhone"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    value={values.branchPhone} />
                                                {errors.branchPhone && touched.branchPhone && (<div className="input-feedback">{errors.branchPhone}</div>)}
                                            </div>

                                        </div>
                                    </div>
                                </div><div className="row">
                                    <div className="col-md-12 mt-4">
                                        <div className="float-right">
                                            <button type="submit" className="btn-primary-fill mr-2">{this.props.t("Save")}</button>
                                            <button type="button" className="btn-primary-outline" onClick={() => this.props.updateLayout('Branch List')}>{this.props.t("Cancel")}</button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        )
                    }}
                </Formik>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        allBranches: state.branchReducer.branches,
        success: state.branchReducer.branchSuccess,
        error: state.branchReducer.branchError,
        branch: state.branchReducer.branch,
    }
}

const mapDispatchToProps = {
    createBranch: createBranchAction,
    updateBranch: updateBranchAction
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BranchAdd));