import Api from '../Api'

export const pets = () => {
    return Api.get('user-service/v1/pet/pets');
};

export const pet =(petId)=>{
	  return Api.get('user-service/v1/pet/dashboard?petId='+petId);
}

export const petProfile =(petId)=>{
    return Api.get('user-service/v1/pet/profile?petId='+petId);
}
export const updatePet =(petId,data)=>{
    return Api.put('user-service/v1/pet/pets/'+petId,data,{ headers: { 'Content-Type': 'multipart/form-data' } });//
}

export const petActivity =(petId='')=>{
    let endpoint='user-service/v1/pet/activities/admin/activities';
    if(petId!==''){
        endpoint =endpoint + '?petId='+petId
    }
    return Api.get(endpoint);
}
export const petActivityDetails =(petActivityId)=>{
    return Api.get('user-service/v1/pet/activities/'+petActivityId);
}
export const petCurrentAttribute =(petId)=>{
    return Api.get('user-service/v1/pet/attribute/currentlevels?petId='+petId);
}
export const petLevelUpgrade=(petId,level,action='up')=>{
    return Api.post('user-service/v1/pet/'+petId+'/attribute/admin/level?petAttributeName='+level+'&status='+action);
}



//previous
export const createOrder = (order) => {
    return Api.post('order', order);
}

export const updateOrder = (orderId, order) => {
    return Api.post('order/' + orderId, order);
}

export const updateOrderStatus = (orderId, orderStatus) => {
    return Api.put('order/update-status/' + orderId, orderStatus);
}

export const deleteOrder = (orderId) => {
    return Api.delete('order/' + orderId);
}

export const deliverOrderToCustomer = (order) => {
    return Api.post('order/deliver', order);
}

export const deliveredOrderedItems = () => {
    return Api.get('delivered-invoice-list');
}

export const verifyPayment = (payment) => {
    return Api.post('verify-payment',payment);
}