const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:b6b0dd88-51fc-4ff5-8833-10602afc79d9",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_FSTyasKi4",
    "aws_user_pools_web_client_id": "1jeun8f7efgbiq5m4shg5e680m",
    "oauth": {
        "domain": "petwalkingnew.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "",
        "redirectSignOut": "",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;