import Dogs from '../pages/Dogs'
import Leaderboard from '../pages/Leaderboard'
import Reports from '../pages/Reports'
import Activity from '../pages/Activity'
import Users from '../pages/Users'
import Pages from '../pages/Pages'


const Roles={
    'Common':[],
    'Admin':[
        {'url':'/main/users','component':Users,'title':'Users','iconClassName':'fa fa-users'},
        {'url':'/main/dogs','component':Dogs,'title':'Pets','iconClassName':'fa fa-paw'} ,
        {'url':'/main/leaderboard','component':Leaderboard,'title':'Leaderboard','iconClassName':'fa fa-signal'}, 
        {'url':'/main/pages','component':Pages,'title':'Pages','iconClassName':'fa fa-file-o'}, 
        {'url':'/main/activity','component':Activity,'title':'Activity','iconClassName':'fa fa-flag-o'}, 
        {'url':'/main/reports','component':Reports,'title':'Reports','iconClassName':'fa fa-line-chart'}, 
    ]
    
} 
export default Roles