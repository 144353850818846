import React, { Component } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import {AlertMessage} from '../helper/AlertBox'



class PageList extends Component {
	constructor(props) {
		super(props);
        this.state = {
            inventoryReport: [],
            filters:{},
            loading :false,
            pageList:[]
        }
	}
    static getDerivedStateFromProps(nextProps, state) {
        
        let nextRes = { ...state };

        if (nextProps.pageList !== undefined)
            nextRes.pageList = nextProps.pageList;

        return { ...nextRes };
    }

    

    handleChange=(e)=>{
        let filter = {...this.state.filters};
        filter[e.target.name]=e.target.value;
        this.props.getInventoryAction(filter);
        this.setState({filters:filter});
    }

	render() {
        
		const columns = [
			{
				dataField: "type",
				text: 'Page'
			},
			{
				dataField: "text",
                text: 'Content',
                formatter:(cell)=>cell!=='' && <div dangerouslySetInnerHTML={{ __html: cell.substring(0, 80)+'...' }} /> 
            },
            {
				dataField: "",
                text: 'Action',
                headerStyle: () => {return { width: '100px' };},
                formatter:(cell,row)=><button onClick={()=>this.props.updateLayout('Page Create',row.id)}><i className="fa fa-pencil"></i></button>
                
            }
		];
       
		return (
            <div>
                {this.props.success!==undefined && this.props.success!=='' && <AlertMessage success={this.props.success}/>}
            <BootstrapTable
                data={this.state.pageList}
                columns={columns}
                key="page"
                keyField="id"
            />
            </div>
		)
	}
}

export default PageList
