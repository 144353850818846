
/* eslint-disable */
import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import withPetHoc from '../Hoc/PetHoc'
import { withTranslation } from 'react-i18next';
import { RegistrationReportListAction, AppOpenReportListAction,WalkstatReportListAction } from '../redux/actions/ReportActions';

// getRegistrationReport 
import classnames from 'classnames';


class Reports extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1',
        }

    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    componentDidMount() {
        this.props.dispatch(RegistrationReportListAction());
        this.props.dispatch(AppOpenReportListAction());
        this.props.dispatch(WalkstatReportListAction());
        
    }

    render() {
        let registration_Data = this.props.registration_report;
        let appopen_Data = this.props.APP_OPEN_REPORT;
        let walkstat_Data = this.props.WALK_STAT_REPORT;
        console.log("walkstat-data", walkstat_Data);
        // console.log("registration-data",registration_Data);
        return (
            <div className="container-fluid">
                <Row>
                    <Col md="12">
                        <Card className="card-box">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="title">Reports</div>
                                    </div>
                                </div>
                            </div>
                            <CardBody className="report-tab">
                                {/* {this.state.activityLoading?<i className="fa fa-spinner fa-pulse fa-3x fa-fw text-center"></i>:this.state.activityList.length > 0 && <ActivityList activityList={this.state.activityList}/>} */}
                                <div>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: this.state.activeTab === '1' })}
                                                onClick={() => { this.toggle('1'); }}
                                            >User Registration
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: this.state.activeTab === '2' })}
                                                onClick={() => { this.toggle('2'); }}
                                            >
                                                App Open
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: this.state.activeTab === '3' })}
                                                onClick={() => { this.toggle('3'); }}
                                            >
                                                Walk Stats
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={this.state.activeTab}>
                                        <TabPane tabId="1">
                                            <Row>
                                                <Col sm="12">
                                                    <div className="pt-4">
                                                        <Row>
                                                            <Col sm="3">
                                                                <div class="detail-box">
                                                                    <h4>Today</h4>
                                                                    <p>{registration_Data != undefined ? registration_Data.todayRegistered : ''}</p>
                                                                </div>
                                                            </Col>
                                                            <Col sm="3">
                                                                <div class="detail-box">
                                                                    <h4>This Week</h4>
                                                                    <p>{registration_Data != undefined ? registration_Data.thisWeekRegistered : ''}</p>
                                                                </div>
                                                            </Col>
                                                            <Col sm="3">
                                                                <div class="detail-box">
                                                                    <h4>This Month</h4>
                                                                    <p>{registration_Data != undefined ? registration_Data.thisMonthRegistered : ''}</p>
                                                                </div>
                                                            </Col>
                                                            <Col sm="3">
                                                                <div class="detail-box">
                                                                    <h4>Total</h4>
                                                                    <p>{registration_Data != undefined ? registration_Data.totalRegistered : ''}</p>
                                                                </div>
                                                            </Col>

                                                        </Row>

                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Row>
                                                <Col sm="12">
                                                    <div className="pt-4">
                                                        <Row>
                                                            <Col sm="3">
                                                                <div class="detail-box">
                                                                    <h4>Today</h4>
                                                                    <p>{appopen_Data != undefined ? appopen_Data.totalToday : ''}</p>
                                                                </div>
                                                            </Col>
                                                            <Col sm="3">
                                                                <div class="detail-box">
                                                                    <h4>This Week</h4>
                                                                    <p>{appopen_Data != undefined ? appopen_Data.totalThisWeek : ''}</p>
                                                                </div>
                                                            </Col>
                                                            <Col sm="3">
                                                                <div class="detail-box">
                                                                    <h4>This Month</h4>
                                                                    <p>{appopen_Data != undefined ? appopen_Data.totalThisMonth : ''}</p>
                                                                </div>
                                                            </Col>
                                                            <Col sm="3">
                                                                <div class="detail-box">
                                                                    <h4>Total</h4>
                                                                    <p>{appopen_Data != undefined ? appopen_Data.allTimeTotal : ''}</p>
                                                                </div>
                                                            </Col>

                                                        </Row>

                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <Row>
                                                <Col sm="12">
                                                    <div className="walkstats">
                                                        <div className="">
                                                            <h3>Today</h3>
                                                        </div>
                                                        <Row>
                                                            <Col sm="4">
                                                                <div class="detail-box">
                                                                    <h4>Total Points</h4>
                                                                    <p>{walkstat_Data != undefined ? walkstat_Data.todayReport.totalPoint : ''}</p>
                                                                </div>
                                                            </Col>
                                                            <Col sm="4">
                                                                <div class="detail-box">
                                                                    <h4>Total Distance Covered</h4>
                                                                    <p>{walkstat_Data != undefined ? walkstat_Data.todayReport.totalDistanceCovered + 'M' : ''} </p>
                                                                </div>
                                                            </Col>
                                                            <Col sm="4">
                                                                <div class="detail-box">
                                                                    <h4>Total Time Walked</h4>
                                                                    <p>{walkstat_Data != undefined ? walkstat_Data.todayReport.totalTimeWalked : ''}</p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                    <div className="walkstats">
                                                        <div className="">
                                                            <h3>This Week</h3>
                                                        </div>
                                                        <Row>
                                                            <Col sm="4">
                                                                <div class="detail-box">
                                                                    <h4>Total Points</h4>
                                                                    <p>{walkstat_Data != undefined ? walkstat_Data.thisWeekReport.totalPoint : ''}</p>
                                                                </div>
                                                            </Col>
                                                            <Col sm="4">
                                                                <div class="detail-box">
                                                                    <h4>Total Distance Covered</h4>
                                                                    <p>{walkstat_Data != undefined ? walkstat_Data.thisWeekReport.totalDistanceCovered + 'M' : ''} </p>
                                                                </div>
                                                            </Col>
                                                            <Col sm="4">
                                                                <div class="detail-box">
                                                                    <h4>Total Time Walked</h4>
                                                                    <p>{walkstat_Data != undefined ? walkstat_Data.thisWeekReport.totalTimeWalked : ''}</p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                    <div className="walkstats">
                                                        <div className="">
                                                            <h3>This Month</h3>
                                                        </div>
                                                        <Row>
                                                            <Col sm="4">
                                                                <div class="detail-box">
                                                                    <h4>Total Points</h4>
                                                                    <p>{walkstat_Data != undefined ? walkstat_Data.thisMonthReport.totalPoint : ''}</p>
                                                                </div>
                                                            </Col>
                                                            <Col sm="4">
                                                                <div class="detail-box">
                                                                    <h4>Total Distance Covered</h4>
                                                                    <p>{walkstat_Data != undefined ? Math.floor(walkstat_Data.thisMonthReport.totalDistanceCovered) + 'M' : ''} </p>
                                                                </div>
                                                            </Col>
                                                            <Col sm="4">
                                                                <div class="detail-box">
                                                                    <h4>Total Time Walked</h4>
                                                                    <p>{walkstat_Data != undefined ? walkstat_Data.thisMonthReport.totalTimeWalked : ''}</p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                    <div className="walkstats">
                                                        <div className="">
                                                            <h3>All Time</h3>
                                                        </div>
                                                        <Row>
                                                            <Col sm="4">
                                                                <div class="detail-box">
                                                                    <h4>Total Points</h4>
                                                                    <p>{walkstat_Data != undefined ? walkstat_Data.allTimeReport.totalPoint : ''}</p>
                                                                </div>
                                                            </Col>
                                                            <Col sm="4">
                                                                <div class="detail-box">
                                                                    <h4>Total Distance Covered</h4>
                                                                    <p>{walkstat_Data != undefined ? Math.floor(walkstat_Data.allTimeReport.totalDistanceCovered) + 'M' : ''} </p>
                                                                </div>
                                                            </Col>
                                                            <Col sm="4">
                                                                <div class="detail-box">
                                                                    <h4>Total Time Walked</h4>
                                                                    <p>{walkstat_Data != undefined ? walkstat_Data.allTimeReport.totalTimeWalked : ''}</p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }

}
const mapStateToProps = state => {
    return {
        registration_report: state.reportReducer.registration_report,
        APP_OPEN_REPORT: state.reportReducer.APP_OPEN_REPORT,
        WALK_STAT_REPORT: state.reportReducer.WALK_STAT_REPORT,
    }
}
export default withTranslation()(connect(mapStateToProps)(Reports));