
/* eslint-disable */
import Amplify ,{ Auth, Hub ,API} from 'aws-amplify'
import config from '../aws-exports'
Amplify.configure(config)

export const login = user => { 
	return Auth.signIn(user.email, user.password)
};

export const userForgotPassword = user => { 
	return Auth.forgotPassword(user.email)
};

export const sendNewPassword = user => { 
	return Auth.forgotPasswordSubmit(user.email,user.code,user.newPassword)
} 


export const logout=() => {
    return  Auth.signOut({ global: true }).then(res=>console.log("Logged Out successfully")).catch(err=>console.log("Logged out error"))
 }



