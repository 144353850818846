
/* eslint-disable */
import * as actionTypes from '../constant';

const INITIAL_STATE = {}

export const invoiceReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case actionTypes.INVOICE_LIST:
            return {
                ...state,
                invoices: action.value
            };
        
        case actionTypes.INVOICE_CREATE:
            return {
                ...state,
                invoices: [...state.invoices,action.value],
                invoice: action.value,
                invoiceSuccess: 'Invoice created successfully.'
            };
        case actionTypes.INVOICE_UPDATE:
            const allInvoices= [...state.invoices]
            let existIndex = allInvoices.findIndex(inv=>inv.invoiceId == action.value.invoiceId)
            
            if(existIndex >= 0){
                allInvoices[existIndex]=action.value
            }
            return {
                    ...state,
                    invoices: allInvoices,
                    invoice: action.value,
                    invoiceSuccess: 'Invoice Updated successfully.'
            };
        case actionTypes.LOG_ERROR:
            return {
                ...state,
                invoiceError: action.value,
                invoiceSuccess:''
            };
        default:
            return {...state,invoiceError: '', invoiceSuccess:''};
    }
}