
/* eslint-disable */
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { createUserAction, updateUserAction } from '../redux/actions/UserActions';
import {updateUserByAdmin } from '../services/UserServices';
import { AlertMessage } from '../helper/AlertBox'

class UserAdd extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: {},
			allUsers: props.allUsers,
			error: '',
			success:'',
			values: this.props.values || {}
		}
	}
	
	updateUser=(values)=>{
		if (this.props.userEmail === undefined) {
				this.props.createUser(values);
		}else {
			if (values.password === '') {
				delete values.password;
			}
			updateUserByAdmin(this.props.userEmail, values).then(res=>{
				this.setState({
					success:'User Updated Successfully'
				})
				//,()=>{setTimeout(this.props.updateLayout("User List"),3000)}
			}).catch(err=>{
				this.setState({error:'Error ,Please update after some time.'})
			});
		}
	}
	
	
	render() {
		console.log(this.state)
		let user = {}
		if (this.props.userEmail !== undefined && this.props.userEmail !== null) {
			user = this.props.allUsers.find(obj => obj.email === this.props.userEmail)
		} else {
			user = { firstName: '', phoneNumber: '', email: '', password: '', lastName: '' }
		}
		

		return (
			<React.Fragment>
				{this.state.error !== '' && <AlertMessage key={new Date().getTime()} error={this.state.error} />}
                {this.state.success !== '' && <AlertMessage key={new Date().getTime()} success={this.state.success} />}
				<Formik
					enableReinitialize={true}
					initialValues={{

						firstName: user !== null && user.firstName !== undefined ? user.firstName : '',
						lastName: user !== null && user.lastName !== undefined ? user.lastName : '',
						phoneNumber: user !== null && user.phoneNumber !== undefined ? user.phoneNumber : '',
						email: user !== null && user.email !== undefined ? user.email : '',
						gender: user !== null && user.gender !== undefined ? user.gender : '',
						birthDate: user !== null && user.birthDate !== undefined ? user.birthDate : '',
						address: user !== null && user.address !== undefined ? user.address : '',
						password: user !== null && user.password !== undefined ? user.password : '',
						
					}}
					onSubmit={values => this.updateUser(values)}
				>
					{props => {
						const {
							values,
							touched,
							errors,
							isSubmitting,
							handleChange,
							handleBlur,
							handleSubmit
						} = props;

						return (
							<form onSubmit={handleSubmit}>
								
								<div className="row">
									<div className="col-md-6">
										<div className="form-group row">
											<label className="col-md-3">First Name</label>
											<div className="col-md-9">
												<input
													type="text"
													name="firstName"
													value={values.firstName}
													className={
														errors.firstName && touched.firstName
															? "form-control error"
															: "form-control"
													}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{errors.firstName && touched.firstName && (<div className="input-feedback">{errors.firstName}</div>)}
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group row">
											<label className="col-md-3">Last Name</label>
											<div className="col-md-9">
												<input
													type="text"
													name="lastName"
													value={values.lastName}
													className={
														errors.lastName && touched.lastName
															? "form-control error"
															: "form-control"
													}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{errors.lastName && touched.lastName && (<div className="input-feedback">{errors.lastName}</div>)}
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group row">
											<label className="col-md-3">Phone#</label>
											<div className="col-md-9">
												<input
													type="text"
													name="phoneNumber"
													value={values.phoneNumber}
													placeholder="+919999999999"
													className={
														errors.phoneNumber && touched.phoneNumber
															? "form-control error"
															: "form-control"
													}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{errors.phoneNumber && touched.phoneNumber && (<div className="input-feedback">{errors.phoneNumber}</div>)}
											</div>

										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group row">
											<label className="col-md-3">{this.props.t('Email')}</label>
											<div className="col-md-9">
												<input
													type="email"
													name="email"
													value={values.email}
													className={
														errors.email && touched.email
															? "form-control error"
															: "form-control"
													}
													readOnly={this.props.userEmail !== '' && this.props.userEmail !== undefined ? true : false}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{errors.email && touched.email && (<div className="input-feedback">{errors.email}</div>)}
											</div>

										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group row">
											<label className="col-md-3">{this.props.t('Address')}</label>
											<div className="col-md-9">
												<input
													type="text"
													name="address"
													value={values.address}
													className={
														errors.address && touched.address
															? "form-control error"
															: "form-control"
													}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{errors.address && touched.address && (<div className="input-feedback">{errors.address}</div>)}
											</div>

										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group row">
											<label className="col-md-3">Birth Date</label>
											<div className="col-md-9">
												<input
													type="date"
													name="birthDate"
													value={values.birthDate}
													className={
														errors.birthDate && touched.birthDate
															? "form-control error"
															: "form-control"
													}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{errors.birthDate && touched.birthDate && (<div className="input-feedback">{errors.birthDate}</div>)}
											</div>

										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group row">
											<label className="col-md-3">Gender</label>
											<div className="col-md-9">
											<div className="form-check form-check-inline col-md-4">
												
														 <input
															type="radio"
															name="gender"
															value="Male"
															checked={values.gender==='Male'?true:false}
															className={
																errors.gender && touched.gender
																	? "form-check-input error"
																	: " form-check-input"
															}
															onChange={handleChange}
															onBlur={handleBlur}
														/>
													<label className="form-check-label">&nbsp; Male </label>
												</div>
												<div className="form-check form-check-inline col-md-4">
													
															<input
															type="radio"
															name="gender"
															value="Female"
															checked={values.gender==='Female'?true:false}
															className={
																errors.gender && touched.gender
																	? "form-check-input error"
																	: "form-check-input"
															}
															onChange={handleChange}
															onBlur={handleBlur}
														/>
													<label className="form-check-label">&nbsp; Female </label>
												</div>
												{errors.gender && touched.gender && (<div className="input-feedback">{errors.gender}</div>)}
											</div>

										</div>
									</div>
									
									{/*<div className="col-md-6">
										<div className="form-group row">
											<label className="col-md-3">{this.props.t('Password')}</label>
											<div className="col-md-9">
												<input
													type="password"
													name="password"
													className={this.props.userId === undefined &&
														errors.password && touched.password
														? "form-control error"
														: "form-control"
													}
													onChange={handleChange}
													onBlur={handleBlur}
													readOnly={this.props.userId !== '' && this.props.userId !== undefined ? true : false}
												/>
												{errors.password && touched.password && (<div className="input-feedback">{errors.password}</div>)}
											</div>

										</div>
									</div>*/}
									<div className="col-md-12 mt-4">
										<div className="float-right">
											<button type="submit"
												className={`btn-primary-fill mr-2`}
											>{this.props.t('Submit')}</button>
											<button type="button"
												className={`btn-primary-outline ${isSubmitting ? 'btn-disabled' : ''}`}
												onClick={() => { this.props.updateLayout('User List') }}
											>{this.props.t('Cancel')}</button>
											{/* <button type="button" className="btn-primary-outline" onClick={() => { this.props.updateLayout('User List') }}>{this.props.t('Cancel')}</button> */}
										</div>
									</div>
								</div>
							</form>)
					}}

				</Formik>

			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {

	return {
		allusers: state.userReducer.users,
		success: state.userReducer.userSuccess,
		error: state.userReducer.userError,
		user: state.userReducer.user,
	}
}

const mapDispatchToProps = {
	createUser: createUserAction,
	updateUser: updateUserAction
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(UserAdd));

//defaultProps
UserAdd.defaultProps ={
    success:''
}