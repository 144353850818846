import Api from '../Api'

export const getUsers = () => {
    return Api.get('user-service/v1/user/users');
};

export const createUser = (user) => {
    return Api.post('user', user);
}

export const updateUser = (userId, user) => {
    return Api.put('user/' + userId, user);
}

export const getPackages = (userId) => {
    return Api.get('packages/user/' + userId);
}

export const getTransactionHistory = (email) => {
    return Api.get('user-service/v1/user/transaction-history?email=' + email);
}


export const updateUserStatus = (data) => {
    let endpoint='user-service/v1/user/';
    endpoint=endpoint + (data.status===1?'enable':'disable');
    endpoint=endpoint+'?email='+encodeURIComponent(data.email)
    return Api.put(endpoint,data);
}
export const updateUserByAdmin=(email,user)=>{
    return Api.put('user-service/v1/user/update?email='+encodeURIComponent(email),user);	
}
