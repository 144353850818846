
/* eslint-disable */
import { combineReducers } from 'redux'
import { authReducer } from './AuthReducer'
import { generalReducer } from './GeneralReducer'
import { petReducer } from './PetReducer'
import { invoiceReducer } from './InvoiceReducer'
import { customerReducer } from './CustomerReducer'
import { packageReducer } from './PackageReducer'
import { userReducer } from './UserReducer'
import { branchReducer } from './BranchReducer'
import { reportReducer } from './ReportReducer'
import { itemReducer } from './ItemReducer'
import { pageReducer } from './PageReducer'

export default combineReducers({
    authReducer,
    generalReducer,
    petReducer,
    customerReducer,
    invoiceReducer,
    packageReducer,
    userReducer,
    branchReducer,
    reportReducer,
    itemReducer,
    pageReducer
})

