import React from 'react'
import Header from './Header'
import Sidebar from './Sidebar'
import MainContainer from './MainContainer'
import Roles from '../routes/RoleRoutes'

class Main extends React.Component{

	constructor(props){
	super(props);

		this.state ={
			toggleMenu : false
		}
		
	}
	toggleButton = () =>{
		//alert('hello');
		this.setState(prevState => ({toggleMenu : !prevState.toggleMenu}))
	}

	render(){
		return(

			<React.Fragment>
				<Header handletoggle={this.toggleButton}/>
		     	<section> 
					<Sidebar handleClass={this.state.toggleMenu} roles={Roles}/>
					<MainContainer handleMainClass={this.state.toggleMenu} {...this.props} roles={Roles}/>
				</section>
			</React.Fragment>

			)
	}
} 

export default Main