
/* eslint-disable */
import * as actionTypes from '../constant';

const INITIAL_STATE = {};

export const customerReducer = (state = INITIAL_STATE, action) => {
    let customers = [];
    let index = false;
    switch (action.type) {
        case actionTypes.CUSTOMER_LIST:
            return {
                ...state,
                customers: action.value,
                customerSuccess: ''
            };
        case actionTypes.CUSTOMER_STATEMENT:
            return {
                ...state,
                customerStatement: action.value,
                customerSuccess: ''
            };
        case actionTypes.CUSTOMER_CREATE:
            return {
                ...state,
                customers: [...state.customers, action.value],
                customer: action.value,
                customerSuccess: 'Customer created successfully'
            };
        case actionTypes.CUSTOMER_UPDATE:
            customers = [...state.customers];
            index = customers.findIndex(order => {
                return order.orderId === action.value.orderId
            });
            customers[index] = action.value
            return {
                ...state,
                customers: customers,
                customer: action.value,
                customerSuccess: 'Customer updated successfully'
            };
        case actionTypes.LOG_ERROR:
            return {
                ...state,
                customerError: action.value,
                customerSuccess:''
            };
        default:
            return {...state,customerError: '', customerSuccess:''};
    }
}