
/* eslint-disable */
import React, {Component} from 'react'
import { Row, Col} from 'reactstrap'
import { ContentBlock, ContentState, convertFromHTML, convertFromRaw, convertToRaw, EditorState } from "draft-js";
import draftToMarkdown from "draftjs-to-markdown";
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Editor } from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';



class CreatePage extends Component{
    constructor(props){
        super(props)
        let nextState={
            templateId:props.templateId!==undefined?props.templateId:'',
            error:'',
            success:''
        }
        if(props.templateId!==undefined && props.templateId!==''){
            let template = props.pageList.find(tpl=>tpl.id===props.templateId)
            nextState.title=template.type
            const contentBlock = htmlToDraft(template.text);
            if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            nextState.editorState = EditorState.createWithContent(contentState);
            }
        }else{
            nextState.title='FAQ'
            nextState.editorState=EditorState.createEmpty()
        }
        
        this.state={...nextState}
    }
    static getDerivedStateFromProps(nextProps, state) {
		let nextRes = {};
		if (state.error !== nextProps.error) {
			if (typeof nextProps.error === 'object') {
				nextRes.error = Object.values(nextProps.error)[0].join(',');
			}
			else {
				nextRes.error = nextProps.error;
			}
			
		}
		if (nextProps.success !== undefined && nextProps.success !== "") {
            nextRes.success=nextProps.success
			nextProps.updateLayout("Page List");
		}
		return {...nextRes};
	}
    onEditorStateChange=(editorState)=>{
        this.setState({
            editorState
          });
    }
    savePageDetail=()=>{
        const { editorState } = this.state;
        let values= {
            'type':this.state.title,
            'text': draftToHtml(convertToRaw(editorState.getCurrentContent()))

        }
        if(this.state.templateId!==''){
            this.props.updatePageAction(values,this.state.title)
        }else{
            this.props.addPageAction(values)
        }
        
    }
   
    render(){
        
        return(
            <Row> 
                <Col lg={6} className="form-group">
                    <select name="title" className="form-control" onChange={(e)=>this.setState({'title':e.target.value})} defaultValue={this.state.title} disabled={this.state.templateId!==''}>
                        <option value="FAQ"  >FAQ</option>
                        <option value="Terms and Conditions">Terms and Conditions</option>
                        <option value="Services">Services</option>
                        <option value="Sales and Demo Tips">Sales and Demo Tips</option>
                        <option value="Privacy Policy">Privacy Policy</option>
                    </select>
                </Col>
                <Col lg={12} className="form-group">
                    <Editor
                        editorState={this.state.editorState}
                        editorClassName="form-control height-250"
                        onEditorStateChange={this.onEditorStateChange}
                        />
                </Col>
                <Col lg={12} className="form-group">
                    <button type="submit" className="btn btn-primary" onClick={this.savePageDetail}>{this.state.templateId!==''?'Update':'Save'} </button>
                </Col>
            </Row>
        )
    }
}
export default CreatePage