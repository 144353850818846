
/* eslint-disable */
import React, { Component } from 'react'
import { Row, Col, Label,Button } from 'reactstrap'
import BootstrapTable from "react-bootstrap-table-next";
import { pet,petProfile,petCurrentAttribute,petActivity,petLevelUpgrade,petActivityDetails } from '../services/PetServices'
import paginationFactory from 'react-bootstrap-table2-paginator';
import ActivityList from './ActivityList'

export default class PetView extends Component {

    constructor(props) {
        super(props)

        this.state = {
            error: '',
            success: '',
            loading:false,
            dataLoading:false,
            upgardeLoading:false,
            petId: props.petId,
            petInfo: {},
            pet:(props.allPets.length) ? props.allPets.find(pt => pt.id === props.petId) : {},
            pageNumber:1,
            perPage:5,
            activityList:[],
            totalActivity:0,
            activityLoading:false,
          
        }
    }
    upgardePetLevel=(row)=>{
       
        this.setState({
            upgardeLoading:row.id
        },()=>{
            petLevelUpgrade(this.props.petId,row.attributeName).then(res=>{
                if(res.data){
                    let updatedPet={...this.state.pet}
                    let newAttr=[...updatedPet.petAttributeDTOList]
                    let attr= newAttr.find(pDto=>pDto.attributeName===row.attributeName)
                    let indx= newAttr.findIndex(pDto=>pDto.attributeName===row.attributeName)
                    if(attr!==undefined){
                        
                        attr.currentLevel=parseInt(attr.currentLevel)+1
                    }
                    newAttr.splice(indx,1)
                    newAttr.push(attr)
                   
                    updatedPet.petAttributeDTOList=newAttr

                    this.setState({
                        upgardeLoading:false,
                        pet:updatedPet
                    })
                }
            }).catch(err=>console.log(err))
        })
        
    }
    //formatter
    actionFormatter=(cell,row)=>{
       // console.log(this.state.upgardeLoading===row.id)
        return <button className="btn btn-primary" onClick={()=>this.upgardePetLevel(row)}>{this.state.upgardeLoading===row.id?<i className="fa fa-spinner fa-pulse fa-2x fa-fw text-center"></i>:'Upgrade Level'}</button>
    }
   

    UNSAFE_componentWillReceiveProps(nextProps) {
        let errors = '';
        if (this.state.error !== nextProps.error) {
            if (typeof nextProps.error === 'object') {
                errors = Object.values(nextProps.error)[0].join(',');
            }
            else {
                errors = nextProps.error;
            }

            this.setState(prevState => ({
                ...prevState,
                error: errors,
                checked: true
            }))
        }


        if (nextProps.order !== undefined && this.state.order !== nextProps.order) {
            this.props.toggleButton();
        }
    }
    getPetProfile=()=>{
        
        this.setState({loading:true},()=>{
            petProfile(this.props.petId)
            .then(res => {
                console.log("petProfile",res.data)
            })
            .catch(err => console.log(err.data))
        })
    }
    getPetActivity=(page=this.state.pageNumber,sizePerPage=this.state.perPage)=>{
        
        this.setState({activityLoading:true},()=>{
            petActivity(this.props.petId)
            .then(res => {
                if(res.data !==undefined && res.data!==''){
                    this.setState({
                        activityList:res.data.petActivityList,
                        totalActivity:res.data.totalRecords,
                        activityLoading:false,
                        error:''
                    })
                }
                
            })
            .catch(err =>{
                console.log(err.data)
                this.setState({activityLoading:false})
            })
        })
    }
    handleTableChange =(page,sizePerPage)=>{
        console.log(page,sizePerPage)
		//this.getPetActivity(page,sizePerPage)
	}
    getPetCurrentAttribute=()=>{
        
        this.setState({loading:true},()=>{
            petCurrentAttribute(this.props.petId)
            .then(res => {
                console.log("getPetCurrentAttribute",res.data)
            })
            .catch(err => console.log(err.data))
        })
    }
    componentDidMount() {
        this.setState({dataLoading:true},()=>{
            pet(this.props.petId)
            .then(res => {
                this.setState({ petInfo: res.data,dataLoading:false })
            })
            .catch(err => console.log(err))
        })
       

       
    }


    render() {
       
        //let pet = this.props.allPets.find(pt => pt.id === this.props.petId)
        if(this.props.allPets.length === 0){
            return null;
        }
        let { petInfo,pet } = this.state
        let attrColumns = [
            { dataField: "attributeName", text: "Attribute Name", headerAlign: 'left', align: 'left' },
            { dataField: "currentLevel", text: "Current Level", headerAlign: 'center', align: 'center' },
            { dataField: "pointsNeededToUpgradeLevel", text: "Points Needed To Upgrade Level", headerAlign: 'left', align: 'left' },
            { dataField: "", text: "Action", headerAlign: 'left', align: 'left',formatter:this.actionFormatter },
        ]
       
        return (
            <div>
                <h3>Profile</h3>
                <Row>
                    <Col lg="6" md="6">
                        <Row className={`form-group`}>
                            <label className="col-md-4">Pet ID#: </label>
                            <Col md="8" className="align-items-center"><label>{this.props.petId}</label></Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" md="6">
                        <Row className={`form-group`}>
                            <label className="col-md-4">Owner:</label>
                            <Col md="8" className="align-items-center">
                                {pet.ownerName}
                            </Col>
                        </Row>
                    </Col>

                </Row>
                <Row>
                    <Col lg="6" md="6">
                        <Row className={`form-group`} >
                            <label className="col-md-4">Age:</label>
                            <Col md="8">{pet.age}</Col>
                        </Row>
                    </Col>
                    <Col lg="6" md="6">
                        <Row className={`form-group`}>
                            <label className="col-md-4">Body Type:</label>
                            <Col md="8">{pet.bodyType !== undefined ? pet.bodyType : ''}</Col>
                        </Row>
                    </Col>
                    <Col lg="6" md="6">
                        <Row className="form-group">
                            <label className="col-md-4">Breed:</label>
                            <Col md="8"> {pet.breed !== undefined ? pet.breed : ''}</Col>
                        </Row>
                    </Col>
                    <Col lg="6" md="6">
                        <Row className="form-group">
                            <label className="col-md-4">Color:</label>
                            <Col md="8">{pet.color !== undefined ? pet.color : ''}</Col>
                        </Row>
                    </Col>
                    <Col lg="6" md="6">
                        <Row className="form-group">
                            <label className="col-md-4">Gender:</label>
                            <Col md="8">{pet.gender !== undefined ? pet.gender : ''}</Col>
                        </Row>
                    </Col>
                    <Col lg="6" md="6">
                        <Row className="form-group">
                            <label className="col-md-4">Pet Name:</label>
                            <Col md="8">{pet.petName !== undefined ? pet.petName : ''}</Col>
                        </Row>
                    </Col>
                    <Col lg="6" md="6">
                        <Row className="form-group">
                            <label className="col-md-4">Weight:</label>
                            <Col md="8">{pet.weight}</Col>
                        </Row>
                    </Col>
                    <Col lg="6" md="6">
                        <Row className="form-group">
                            <label className="col-md-4">Total Earned Point:</label>
                            <Col md="8">{pet.totalEarnedPoint}</Col>
                        </Row>
                    </Col>
                    <Col lg="6" md="6">
                        <Row className="form-group">
                            <label className="col-md-4">Total Remaining Point:</label>
                            <Col md="8">{pet.totalRemainingPoint}</Col>
                        </Row>
                    </Col>
                    <Col lg="6" md="6">
                        <Row className="form-group">
                            <label className="col-md-4">Total Running Mile:</label>
                            <Col md="8">{parseFloat(pet.totalRunningMile).toFixed(2)}</Col>
                        </Row>
                    </Col>

                </Row>

                <Row className="mb-5">
                    <Col lg="2" sm="3" xs="12">
                        <Label htmlFor="item">Image:</Label>
                    </Col>
                    <Col lg="10" sm="9" xs="12">
                        <div className="upload-image">
                            <div className="imgPreview">
                                <img src={pet.profileImageName} className="img-fluid" alt='' />
                            </div>
                        </div>
                    </Col>

                </Row>
                {this.state.dataLoading?<Row className="text-center"> <i className="fa fa-spinner fa-pulse fa-3x fa-fw text-center"></i>
                </Row>:
                <div className="card-columns petData">
                        <div className="card bg-info">
                            <div className="card-header text-center">Distance Data</div>
                            {petInfo.distanceData !== undefined &&
                            <div className="card-body text-center">
                                <p className="card-text">All Time Walked: <b>{parseFloat(petInfo.distanceData.allTimeWalked).toFixed(2)}</b></p>
                                <p className="card-text">This Month Walked: <b>{parseFloat(petInfo.distanceData.thisMonthWalked).toFixed(2)}</b></p>
                                <p className="card-text">This week Walked: <b>{parseFloat(petInfo.distanceData.thisWeekWalked).toFixed(2)}</b></p>
                                <p className="card-text">Today Walked: <b>{parseFloat(petInfo.distanceData.todayWalked).toFixed(2)}</b></p>
                            </div>}
                            <div className="card-footer">Next Milestone Distance:{petInfo.nextMilestoneDistance}</div>
                        </div>
                        <div className="card bg-info">
                            <div className="card-header text-center">Point Data</div>
                            {petInfo.pointData !== undefined && <div className="card-body text-center">
                                <p className="card-text">Today Earned: <b>{petInfo.pointData.todayEarned}</b> </p>
                                <p className="card-text">This Week Earned: <b>{petInfo.pointData.thisWeekEarned}</b></p>
                                <p className="card-text">This Month Earned: <b>{petInfo.pointData.thisMonthEarned}</b></p>
                                <p className="card-text">All Time Earned: <b>{petInfo.pointData.allTimeEarned}</b></p>
                            </div>}
                            <div className="card-footer"></div>
                        </div>
                        <div className="card bg-info">
                            <div className="card-header text-center">Time Data</div>
                            {petInfo.timeData !== undefined &&<div className="card-body text-center">
                                <p className="card-text">Today Duration: <b>{petInfo.timeData.todayDuration}</b></p>
                                <p className="card-text">This Week Duration: <b>{petInfo.timeData.thisWeekDuration}</b></p>
                                <p className="card-text">This Month Duration: <b>{petInfo.timeData.thisMonthDuration}</b></p>
                                <p className="card-text">All Time Duration: <b>{petInfo.timeData.allTimeDuration}</b></p>
                            </div>}
                            <div className="card-footer"></div>
                        </div>

                </div>
                
                }
                <br/>
                <h3>Attribute List</h3>
                <Row className="mb-5">
                    {this.state.loading || pet.petAttributeDTOList == undefined ?<i className="fa fa-spinner fa-pulse fa-3x fa-fw text-center"></i>:<BootstrapTable
                        keyField="id"
                        key='PetAttrlist'
                        data={pet.petAttributeDTOList}
                        columns={attrColumns}
                    />}
                </Row>
                <Row>
                    {/* <Button className="btn-primary-fill ml-2" onClick={this.getPetProfile}>Profile</Button> */}
                    {/* <Button className="btn-primary-fill ml-2" onClick={()=>this.getPetActivity(this.state.pageNumber,this.state.perPage)}>Activity</Button> */}
                    {/* <Button className="btn-primary-fill ml-2" onClick={this.getPetCurrentAttribute}>Pet's Current Attributes</Button> */}

                </Row>
                <Row className="mb-5">
            {this.state.error!==''&& <div className="alert alert-danger">{this.state.error}</div>}
                    {this.state.activityLoading?<i className="fa fa-spinner fa-pulse fa-3x fa-fw text-center"></i>:this.state.activityList.length > 0 && <ActivityList activityList={this.state.activityList}/>}
                </Row>
            </div>

        )
    }
}


