
/* eslint-disable */
import * as actionTypes from '../constant';

const INITIAL_STATE = {}

export const pageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.PAGE_LIST:
            return {
                ...state,
                success:'',
                pageList: action.value
            };
        case actionTypes.PAGE_CREATE:
                return {
                    ...state,
                    pageList: [...state.pageList, action.value],
                    success:'Page Created successfully'
                };
        case actionTypes.PAGE_UPDATE:
            let pageList=[...state.pageList]
            let indx= pageList.findIndex(tpl=>tpl.id===action.value.id)
            if(indx>=0){
                pageList[indx]=action.value 
            }
                    return {
                        ...state,
                        pageList: pageList,
                        success:'Page updated successfully'
                    };
        default:
            return { ...state };
    }
}