import React, { Component } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import PetList from '../components/PetList'
import PetView from '../components/PetView'
import PetUpdate from '../components/PetUpdate'
import PetHoc from '../Hoc/PetHoc'


class Dogs extends Component {


    constructor(props) {
        super(props);

        this.state = {
            hideShow: false,
            Pets: [],
            customers: [],
            loggedIn: '',
            error: '',
            PetId: '',
            showPetUpdate:false
        }
    }

    toggleButton = (PetId = '') => {
        this.setState({
                hideShow: !this.state.hideShow,
                showPetUpdate:false,
                PetId: PetId
            });  
    }
    changeUpdateStatusView=()=>{
        this.setState({
            showPetUpdate:true
        })
    }

    render() {
    
        return (
            <div className="container-fluid">
                <Row>
                    <Col md="12">
                        <Card className="card-box">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="title">
                                            {this.state.hideShow ? this.state.PetId !== '' && this.state.showPetUpdate?'Update Pet':('View Pet') : ('PetList')}
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-right">

                                        {this.state.hideShow && <React.Fragment>
                                            <button className="btn-primary-fill  mt-3"
                                            onClick={() => this.toggleButton()}> { 'Pet List'}</button>
                                            {!this.state.showPetUpdate && <button className="btn-primary-fill  mt-3 ml-2"
                                            onClick={this.changeUpdateStatusView}> { 'Update Pet'}</button>}
                                            </React.Fragment>}
                                    </div>
                                </div>
                            </div>
                            <CardBody>
                                {this.state.hideShow ?this.state.showPetUpdate?
                                                    <PetUpdate petId={this.state.PetId} 
                                                    toggleButton={() => this.toggleButton()} 
                                                    {...this.props} /> 
                                                    : <PetView petId={this.state.PetId} 
                                                        toggleButton={() => this.toggleButton()} 
                                                        {...this.props} /> 
                                                     : <PetList 
                                                         toggleButton={this.toggleButton} 
                                                         lookupData={this.state.lookupData} 
                                                         {...this.props} />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }

}

export default PetHoc(Dogs);