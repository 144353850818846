
/* eslint-disable */
import * as actionTypes from '../constant';

const INITIAL_STATE = {
    pets: [],
};

export const petReducer = (state = INITIAL_STATE, action) => {
    let pets = [];
    let index = false;
    switch (action.type) {
        case actionTypes.PET_LIST:
            return {
                ...state,
                pets: action.value,
                petSuccess: ''
            };
      
        case actionTypes.ORDER_CREATE:
            return {
                ...state,
                pets: [...state.pets, action.value],
                pet: action.value,
                petsuccess: 'pet created successfully'
            };
        case actionTypes.PET_UPDATE:
            pets = [...state.pets];
            index = pets.findIndex(pet => {
                return pet.id === action.value.id
            });
            pets[index] = action.value
            return {
                ...state,
                pets: pets,
                pet: action.value,
                petsuccess: 'pet updated successfully.'
            };
        case actionTypes.ORDER_STATUS:
            pets = [...state.pets];
            index = pets.findIndex(order => {
                return order.orderId === action.value.orderId
            });
            pets[index] = action.value
            return {
                ...state,
                pets: pets,
                pet: action.value,
                petsuccess: 'pet status updated successfully.'
            };
        case actionTypes.ORDER_DELETE:
            pets = [...state.pets];
            index = pets.findIndex(order => {
                return order.orderId === action.value.orderId
            });
            pets.splice(index, 1)
            return {
                ...state,
                pets: pets,
                pet: action.value,
                petError:'',
                petsuccess: 'pet deleted successfully.'
            };
        case actionTypes.LOG_ERROR:
            return {
                ...state,
                petError:action.value,
                petsuccess: ''
            };
        case actionTypes.CLEAR_ERROR:
            return {
                ...state,
                petError: '',
                petsuccess: ''
            };
        default:
            return { ...state, petError: '', petsuccess: '' };
    }
}
