
/* eslint-disable */
import React, { Component } from 'react'
import { Card, CardBody } from 'reactstrap'
import UserAdd from '../components/UserAdd'
import UserList from '../components/UserList'
import ViewUser from '../components/ViewUser'
import withPetHoc from '../Hoc/PetHoc'
import { AlertMessage } from '../helper/AlertBox'

class Users extends Component {

    constructor(props) {
        super(props);

        this.state = {
            customerLayout: "User List",
            userEmail: null,
            success:'',
            error:''
        }
    }
    // static getDerivedStateFromProps(nextProps, state) {
    //     let nextResult={}
    //     if (nextProps.success !== undefined && nextProps.success !== "") {
    //         nextResult.success=state.userEmail +'_'+nextProps.success 
    //     }
    //     if (nextProps.error !== undefined && nextProps.error !== "") {
	// 		nextResult.error=nextProps.error
    //     }
    //     return {...nextResult}
    // }
    userList = (props) => {
        return (
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">{this.props.t('UserList')}</div>
                        </div>
                       {/* <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Add User") }}>{this.props.t('AddUser')}  </button>
                        </div>*/}
                    </div>
                    
                </div>
                <CardBody>
                    <UserList handleOrderView={this.updateLayout} />
                </CardBody>
            </Card>
        )
    }

    userCreate = (props) => {
        return (
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("User List") }}>{this.props.t('UserList')}</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <UserAdd updateLayout={this.updateLayout} {...this.props} />
                </CardBody>
            </Card>
        )
    }

    viewUserDetail = (props) => {
        return (<Card className="card-box">
            <div className="card-header">
                <div className="row">
                    <div className="col-md-6">
                        <div className="title">{this.props.t('UserDetails')} </div>
                    </div>
                    <div className="col-md-6">
                        <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("User List") }}>{this.props.t('UserList')}</button>
                    </div>
                </div>
            </div>
            <CardBody>
                <ViewUser updateLayout={this.updateLayout} userEmail={this.state.userEmail} {...props} />
            </CardBody>
        </Card>
        )
    }

    updateUser = () => {
        return (
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">{this.props.t('EditUser')} </div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("User List") }}>{this.props.t('UserList')}</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <UserAdd updateLayout={this.updateLayout} {...this.props} userEmail={this.state.userEmail} />
                </CardBody>
            </Card>
        )
    }

    updateLayout = (layout, email = null) => {
        this.setState({ customerLayout: layout, userEmail: email,success:'' });
    }

    getCustomerComponent(layout) {
        switch (layout) {
            case "User List": return this.userList(this.props);

            case "Add User": return this.userCreate(this.props);

            case "Edit User": return this.updateUser(this.props);

            case "View User": return this.viewUserDetail(this.props);

            default:
                return false;
        }
    }
    componentWillUpdate(prevProps,prevState){
        //console.log(prevProps,prevState)
    }
    componentDidMount() {
        document.title = "Users"
    }
   
    render() {

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        {this.getCustomerComponent(this.state.customerLayout)}
                    </div>
                </div>
            </div>
        )
    }
}



export default withPetHoc(Users)