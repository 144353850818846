
/* eslint-disable */
import * as actionTypes from '../constant';
import { pageList,addPage ,updatePage} from '../../services/PageServices';

export const getPageList = () => {

    return dispatch => {
        pageList().then(res => {

            if (res.data !== undefined) {
                dispatch( {
                    type: actionTypes.PAGE_LIST,
                    value: res.data.templates
                });
                dispatch(clearError());
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
           
            let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
            dispatch(error(errormsg ));
        });
    }
}

export const addPageAction = (data) => {

    return dispatch => {
        addPage(data).then(res => {

            if (res.data !== undefined) {
                dispatch( {
                    type: actionTypes.PAGE_CREATE,
                    value: res.data
                });
                dispatch(clearError());
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
          
            let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
            dispatch(error(errormsg ));
        });
    }
}

export const updatePageAction = (data,type) => {

    return dispatch => {
        updatePage(data,type).then(res => {

            if (res.data !== undefined) {
                dispatch( {
                    type: actionTypes.PAGE_UPDATE,
                    value: res.data
                });
                dispatch(clearError());
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
            dispatch(error(errormsg ));
        });
    }
}



const error = (err) => {
    return {
        type: actionTypes.LOG_ERROR,
        value: err
    }
}
const clearError = (err) => {
    return {
        type: actionTypes.CLEAR_ERROR,
    }
}