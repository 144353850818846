import React from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next'
import { petListAction } from '../redux/actions/PetActions'
import { customerListAction } from '../redux/actions/CustomerActions'
import { userListAction } from '../redux/actions/UserActions'
// import { RegistrationReportListAction,AppOpenReportListAction } from '../redux/actions/ReportActions'

const PetHoc = BaseComponent => {

    class Wrapper extends React.Component {
        componentDidMount() {
           
            //this.props.customerListAction();
            this.props.userListAction();
            this.props.petListAction();
        }
        render() {

            return (<BaseComponent  {...this.props} />)
        }
    }
    const mapStateToProps = state => {
        return {
            allPets: state.petReducer.pets,
            lookupData:state.generalReducer.lookupData,
            allCustomers: state.customerReducer.customers,
            allUsers: state.userReducer.users,
            userProfile:state.authReducer.userProfile
        }
    }
    const mapDispatchToProps = {
        petListAction,
        customerListAction,
        userListAction,
    }
    return withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Wrapper));

}

export default PetHoc