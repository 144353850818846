
/* eslint-disable */
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from 'react-bootstrap-table2-paginator';
import { leaderboardListData } from '../services/LeaderboardServices'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { AlertMessage } from '../helper/AlertBox';

const onColumnMatch = ({ searchText, value, column, row }) => {
	if (searchText !== '' || searchText !== null) {
		return row.branchName.trim().toLowerCase().indexOf(searchText) < 0 ? false : row
	}
	return row
}

class LeaderboardList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			allList: [],
			success: props.success,
			error: props.error,
			branchId: '',
			branchStatus: '',
			loading:false,
			totalSize:50,
			page:1,
			sizePerPage:10,
			leaderData:[],
			timePeriod:'ALL Time',
			filterType:'Points Earned'
		}
	}
	getRemoteData=(page=this.state.page,sizePerPage=this.state.sizePerPage,filterType=this.state.filterType,timePeriod=this.state.timePeriod)=>{
		this.setState({loading:true},()=>{
			leaderboardListData(page,sizePerPage,filterType,timePeriod).then(res=>
				this.setState({
					loading:false,
					allList:res.data.rankList!==null?res.data.rankList:[],
					page:res.data.rankList!==null?page:1,
					sizePerPage:res.data.rankList!==null?sizePerPage:10,
					totalSize:res.data.totalRecords,
					leaderData:res.data.rankList,
				})
				).catch(err=>console.log(err.data))
		})		
	}
	handleTableChange =(action,table)=>{
		
		//table.searchText
		this.getRemoteData(table.page,table.sizePerPage)
	}
	filterBoard=()=>{
		this.getRemoteData()
	}
	getTimePeriodValue=(e)=>{
		this.setState({
			timePeriod:e.target.value
		},() => {
			this.getRemoteData()
		});
		// console.log("timePeriod",this.state.timePeriod);
	}
	getfilterTypeValue=(e)=>{
		this.setState({
			filterType:e.target.value
		},() => {
			this.getRemoteData()
		});
		// console.log("timePeriod",this.state.filterType);
	}
	componentDidMount() {
		document.title="Leader Board"
		this.getRemoteData()		
		console.log("leaderData",this.state.leaderData);
	}
	

	secondsToMinutes=(timeInSeconds)=>{
			var pad = function(num, size) { return ('000' + num).slice(size * -1); },
		time = parseFloat(timeInSeconds).toFixed(3),
		hours = Math.floor(time / 60 / 60),
		minutes = Math.floor(time / 60) % 60,
		seconds = Math.floor(time - minutes * 60);
		// milliseconds = time.slice(-3);
		return pad(hours, 2) + 'h:' + pad(minutes, 2) + 'm:' + pad(seconds, 2)+'s';
        // return Math.floor(time / 3600)+':'+(time / 60)+':'+Math.floor(time % 60);
	}
	getrecordsRows = (data) => {
		console.log("data",data);
		let row = data.map((data, index) => {
			return {
				rank: data.id ,
				petName: data.petName,
				totalEarnedPoint: data.totalEarnedPoint,
				totalTimeWalked:data.totalTimeWalked,
				// totalTimeWalked: this.secondsToMinutes(data.totalTimeWalked),
				totalRunningMile: data.totalRunningMile,
		}
	});
		return row;
	}

	render() {
		// const { SearchBar } = Search;
		
        let rows = this.getrecordsRows(this.state.allList);
		const defaultSorted = [{
            dataField: 'totalEarnedPoint',
            order: 'desc'
		}];
		let rowEvents = {
			onClick: (e, row, rowIndex) => {		
				console.log("tableclick",this.props);
				console.log("tableclick",row);
				this.props.toggleButton(row.rank);
			},
			onMouseEnter: (e, row, rowIndex) => {
			}
		 };
		 let tmdbData = {
		columns : [
			{
				dataField: "rank",
				text: ("Rank")
			},
			{
				dataField: "petName",
				text: ("Pet Name")
			},
			{
				dataField: "totalEarnedPoint",
				text: ("Points")
			},
			{
				dataField: "totalRunningMile",
				text: ("Miles Walked")
			},
			{
				dataField: "totalTimeWalked",
				text: ("Time Walked")
			}
		],		
		rows: rows
		};

		const rowClasses = 'manage-table-row';
		return (
			<React.Fragment>
				<Row>
					{this.state.success !== '' && this.state.success !== undefined ? <Col lg="12" md="12"><AlertMessage key={new Date().getTime()} success={this.state.success} /></Col> : ''}
				</Row>
				<Row>
					<Col lg="12" md="12">						
						{this.state.loading ?<div className="text-center">
						<i className="fa fa-spinner fa-pulse fa-3x fa-fw text-center"></i>
						</div>:
							<React.Fragment>
								
								<ToolkitProvider
									keyField="id"
									key='leaderboard'
									// data={this.state.allList}									
                                    data={tmdbData.rows} 
                                    columns={tmdbData.columns} 
									// columns={this.columns}
									search
								>
									{
										props => (
											<div>
												<Row>
													<Col lg={3} className="form-group"> 
														
														<select name="filterType" className="form-control" defaultValue={this.state.filterType}  onChange={this.getfilterTypeValue}>
															<option value="Points Earned">Points Earned</option>
															<option value="Miles Walked">Miles Walked</option>
															<option value="Time Walked">Time Walked</option>
														</select>
													</Col>
													<Col lg={3} className="form-group">
														
														<select name="timePeriod" className="form-control" defaultValue={this.state.timePeriod} onChange={this.getTimePeriodValue}>
															<option value="This Week">This Week</option>
															<option value="This Month">This Month</option>
															<option value="ALL Time">ALL Time</option>
														</select>
													</Col>
													<Col lg={2} >
													
														{/* <button className="btn btn-primary" onClick={this.filterBoard} > Apply Filter</button> */}
													</Col>
													<Col lg={4}>
														{/* <SearchBar {...props.searchProps} /> */}
													</Col>
																
												</Row>
												<div className="table-responsive">
													<BootstrapTable {...props.baseProps} 
													remote
													keyField="id"
													onTableChange ={this.handleTableChange}													
													rowEvents={rowEvents}													
													rowClasses={rowClasses}
													pagination={paginationFactory({totalSize:this.state.totalSize,page:this.state.page,sizePerPage:this.state.sizePerPage }) } 
													defaultSorted={defaultSorted} 
													/>
													{/* onPageChange:this.handleTableChange,onSizePerPageChange:this.handleTableChange, */}
												</div>
											</div>
										)
									}
								</ToolkitProvider>
							</React.Fragment>
							}
					</Col>
				</Row>
			</React.Fragment>
		)
	}
}
export default LeaderboardList
