
/* eslint-disable */
import * as actionTypes from '../constant';

const INITIAL_STATE = {};

export const packageReducer = (state = INITIAL_STATE, action) => {
    let acceptedPackages=[],outgoingPackages=[],incomingPackages=[];
    let newaccepted,newoutgoing={};
    let index=false;
    switch (action.type) {
        case actionTypes.INCOMING_PACKAGE_LIST:
            return {
                ...state,
                incomingPackages: action.value,
                packageSuccess:''
            };
        case actionTypes.OUTING_PACKAGE_LIST:
            return {
                ...state,
                outgoingPackages: action.value,
                packageSuccess:''
            };
        case actionTypes.ACCEPTED_PACKAGE_LIST:
            return {
                ...state,
                acceptedPackages: action.value,
                packageSuccess:''
            };
        case actionTypes.TRANSFER_PACKAGE:
            acceptedPackages=[...state.acceptedPackages]
            outgoingPackages=[...state.outgoingPackages]
            index=acceptedPackages.findIndex(pack => {
                return pack.trackingId === action.value.trackingId
            });

            newoutgoing=acceptedPackages[index];
            acceptedPackages.splice(index,1);
            return {
                ...state,
                acceptedPackages: acceptedPackages,
                outgoingPackages: [...outgoingPackages,newoutgoing],
                packageSuccess:'Package transfered successfully.'
            };
        case actionTypes.CANCEL_PACKAGE:
            outgoingPackages=[...state.outgoingPackages]
            acceptedPackages=[...state.acceptedPackages]
            index=outgoingPackages.findIndex(pack => {
                return pack.trackingId === action.value.trackingId
            });

            newaccepted=outgoingPackages[index];
            outgoingPackages.splice(index,1);
            return {
                ...state,
                acceptedPackages: [...acceptedPackages,newaccepted],
                outgoingPackages: outgoingPackages,
                packageSuccess:'Package transfer request cancelled successfully.'
            };
        case actionTypes.ACCEPT_PACKAGE:
            incomingPackages=[...state.incomingPackages]
            acceptedPackages=[...state.acceptedPackages]
            index=incomingPackages.findIndex(pack => {
                return pack.trackingId === action.value.trackingId
            });

            newaccepted=incomingPackages[index];
            incomingPackages.splice(index,1);
            return {
                ...state,
                acceptedPackages: [...acceptedPackages,newaccepted],
                incomingPackages: incomingPackages,
                packageSuccess:'Package request accepted successfully.'
            };
        case actionTypes.ASSIGN_PACKAGE:
            return {
                ...state,
                assignedInvoice:action.value,
                packageSuccess:'Package Assigned successfully.',
        };
        case actionTypes.LOG_ERROR:
            return {
                ...state,
                packageError: action.value,
                packageSuccess:''
            };
        case actionTypes.CLEAR_ERROR:
            return {
                ...state,
                packageError: '',
                packageSuccess:''
            };
        case actionTypes.DELIVER_ORDER:

             acceptedPackages=[...state.acceptedPackages]
            if(acceptedPackages.length>0) {
                action.value.invoiceId.length > 0 && action.value.invoiceId.forEach(inv => {
                        let getIndex = acceptedPackages.findIndex(pack => {
                             return pack.invoiceId == inv
                        });
                    acceptedPackages.splice(getIndex,1);
                })
                acceptedPackages=[...acceptedPackages,...action.value.packages]
            }else{
                acceptedPackages=[...action.value.packages];
            }

            return {
                ...state,
                acceptedPackages: acceptedPackages,
                orderError:'',
                orderSuccess: 'Package delivered successfully'
            };

        default:
            return {...state,packageError: '', packageSuccess:''};
    }
}
