/* eslint-disable */
import React, { Component } from 'react'
import { Row, Col, Card } from 'reactstrap'
import { connect } from 'react-redux';
import Logo from '../assets/images/logo.png'
import { loginUser,forgotPassword,setNewPassword } from '../redux/actions/AuthActions'
import {Formik} from 'formik';
import {getAuthRedirectUrl} from '../helper/General'
import { AlertMessage } from '../helper/AlertBox'
import * as Yup from 'yup';

class Login extends Component {
	state = {
		email: '',
		password: '',
		formErrors: { email: '', password: '' },
		emailValid: false,
		passwordValid: false,
		formValid: false,
		loggedIn: '',
		error: '',
		loading:false,
		isSubmitButtonPress:false,
		forgotPasswordForm:false,
		forgetPasswordCodeForm:false
	}
	forgotPassword=()=>{
		localStorage.clear();
		this.setState({forgotPasswordForm:!this.state.forgotPasswordForm})
	}
	onSubmit = (user) => {
		if(this.state.forgotPasswordForm){
			this.props.forgotPasswordCallback(user)
		}else{
			this.setState({loading:true},()=>{this.props.login(user)})
		}
		
	}
	submitVerifyCode=(values)=>{
		
		values.email=this.state.email
		
		this.props.saveNewPassword(values)
	}
	static getDerivedStateFromProps(nextProps, state) {
		let nextResult={}
		
		nextResult.error=''
		if(nextProps.loggedIn===true){
			nextProps.history.push(nextProps.redirectTo);
		}else if(nextProps.setPasswordForm!==undefined && nextProps.setPasswordForm===true){
			nextResult.forgetPasswordCodeForm=true
			nextResult.email=nextProps.email
			nextResult.error=nextProps.error
			nextResult.loading=false	
			
		}else if(nextProps.loggedIn===false && state.error!==nextProps.error){
			nextResult.loggedIn=nextProps.loggedIn
			nextResult.error=nextProps.error
			nextResult.loading=false
			
		}
		
        return {...nextResult}
    }

	componentDidMount(){
		document.title='Login'
		if(this.props.loggedIn===true){
			this.props.history.push(getAuthRedirectUrl()); 
		}
	}

	render() {
		console.log(this.state)
		return (

			<section>
				<div className="container">
					<Row>
						<Col lg="5" md="7" className="login-container">
							<Card className="card-box p-4">
								<div className="card-header text-center"><img src={Logo} className="img-fluid" width="20%" alt="logo"/></div>
								{!this.state.forgetPasswordCodeForm?<div className="card-body">
									<Formik
										initialValues = {{
											email: '',
											password: ''
										}}
										onSubmit={values => {
                                         this.onSubmit(values)
                                        }}
										validationSchema={Yup.object().shape({
                                            email: Yup.string()
                                                .email()
                                                .required("Required"),
											password: Yup.string().required('No password provided.')
                                        })}
									>
										{
											props => {
												const {
													errors,
													handleChange,
                                                    handleSubmit,
                                                    handleBlur,
                                                    touched
												} = props;
												return (
													<form  onSubmit={handleSubmit} >
														<div className={`form-group`}>
															<label>Username</label>
															<input type="text" name="email"   onBlur={handleBlur} className={ errors.email && touched.email ? "form-control error" : "form-control"
                                                            }  placeholder="" onChange={handleChange} />
                                                            {errors.email && touched.email && (
																<div className="input-feedback">{errors.email}</div>
                                                            )}
														</div>
														{this.state.forgotPasswordForm===false&&<div className={`form-group`}>
															<label>Password</label>
															<input type="password" name="password" onBlur={handleBlur} className={ errors.email && touched.email ? "form-control error" : "form-control"
                                                            } placeholder="" onChange={handleChange} />
                                                            {errors.password && touched.password && (
																<div className="input-feedback">{errors.password}</div>
                                                            )}
														</div>
														}
														<span className="text text-primary" onClick={this.forgotPassword}> {this.state.forgotPasswordForm?'Login':'Forgot Password?'}</span>
                                                        {!this.state.loggedIn  && this.state.error!=='' && <p className="alert alert-danger mt-2 ">{this.state.error}</p>}
														{this.state.loading?<button className="btn-primary-fill d-block text-center mt-2 w-100"><i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i></button>:
														<button type="submit" className="btn-primary-fill d-block text-center mt-2 w-100">{this.state.forgotPasswordForm===false?'Login':'Forgot Password'}</button>}

													</form>
												)
											}
										}
									</Formik>
								</div>:<div className="card-body">
									{this.state.error===''&& <p className="alert alert-success">Verification code sent on email successfully.</p>}
									<Formik
									initialValues={{code:'',newPassword:''}}
									onSubmit={this.submitVerifyCode}
									validationSchema={Yup.object().shape({code: Yup.string().required("Required")})}
									>
									{
										props=>{
											const {errors,handleChange,handleSubmit,handleBlur,touched}=props
											return(
												<form  onSubmit={handleSubmit} >
														<div className={`form-group`}>
															<label>Code</label>
															<input type="text" 
																name="code"   
																onBlur={handleBlur} 
																className={ errors.code && touched.code ? "form-control error" : "form-control"}  
																placeholder="Enter code" 
																onChange={handleChange} />
                                                            {errors.code && touched.code && <div className="input-feedback">{errors.code}</div>}
														</div>
														<div className={`form-group`}>
															<label>New Password</label>
															<input type="password" 
																name="newPassword"   
																onBlur={handleBlur} 
																className={ errors.newPassword && touched.newPassword ? "form-control error" : "form-control"}  
																placeholder="Enter new password" 
																onChange={handleChange} />
                                                            {errors.newPassword && touched.newPassword && <div className="input-feedback">{errors.newPassword}</div>}
														</div>
														{!this.state.loggedIn  && this.state.error!=='' && <p className="alert alert-danger mt-2 ">{this.state.error}</p>}
														<button type="submit" className="btn-primary-fill d-block text-center mt-2 w-100">Set New Password</button>
												</form>
												)
										}
									}	
									</Formik>
								</div>}
							</Card>
						</Col>
					</Row>
				</div>
			</section>

		)
	}
}
const mapStateToProps= state =>{
	
	return {
		loggedIn: state.authReducer.loggedIn,
		redirectTo: state.authReducer.redirectTo,
		forgetPasswordCode: state.authReducer.forgetPasswordCode,
		setPasswordForm: state.authReducer.setPasswordForm,
		email: state.authReducer.email,
		error:state.authReducer.loginError
	}
}

const mapDispatchToProps={
	login:loginUser,
	forgotPasswordCallback:forgotPassword,
	saveNewPassword:setNewPassword
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);