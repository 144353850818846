import Api from '../Api'

export const getInventoryReport = (filter) => {
    return Api.get('inventory-report', { params:filter});
};

export const getWastageReport = (filter) => {
    return Api.get('wastage-report', { params:filter});
}

export const getSalesReport = (filter) => {
    return Api.get('sales-report', { params:filter});
}
export const getRegistrationReport = () => {
    return Api.get('user-service/v1/user/registration-report');
}
export const getAppOpenReport = () => {
    return Api.get('user-service/v1/user/app-opening');
}
export const getWalkstatReport = () => {
    return Api.get('user-service/v1/user/report');
}