
/* eslint-disable */
import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import BranchAdd from '../components/BranchAdd';
import LeaderboardList from '../components/LeaderboardList';
// import PetList from '../components/PetList';
import PetView from '../components/PetView';
import PetUpdate from '../components/PetUpdate';
import PetHoc from '../Hoc/PetHoc'


class Leaderboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            customerLayout: "Branch List",
            branchId: '',
            hideShow:false,
            showPetUpdate:false
        }
    }

    toggleButton = (PetId = '') => {
        this.setState({
                hideShow: !this.state.hideShow,
                showPetUpdate:false,
                PetId: PetId
            });  
    }

    leaderboardList = (props) => {
        return (
            <div class="container-fluid">
            <div className="row">
            <div className="col-md-6">
                <div className="title">
                    {/* {this.state.hideShow ? this.state.PetId !== '' && this.state.showPetUpdate ? 'Update Pet' : ('View Pet') : ('PetList')} */}
                </div>
            </div>
          
        </div>
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">Leaderboard</div>
                        </div>
                        <div className="col-md-6 text-right">
                        {this.state.hideShow && <React.Fragment>
                            <button className="btn-primary-fill  mt-3"
                                onClick={() => this.toggleButton()}> {'Leaderboard List'}</button>
                        </React.Fragment>}
                        </div>
                    </div>
                </div>
                <CardBody>
                    
                    {this.state.hideShow ?this.state.showPetUpdate?
                                                    <PetUpdate petId={this.state.PetId} 
                                                    toggleButton={() => this.toggleButton()} 
                                                    {...this.props} /> 
                                                    : <PetView petId={this.state.PetId} 
                                                        toggleButton={() => this.toggleButton()} 
                                                        {...this.props} /> 
                                                     : <LeaderboardList toggleButton={this.toggleButton} handleOrderView={(layout, branchId) => this.updateLayout(layout, branchId)} {...this.props} />
                                }
                </CardBody>
                
            </Card>
            </div>
        )
    }

    branchCreate = (props) => {
        return (
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">CreateBranch</div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Branch List") }}>LeaderboardList</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <BranchAdd updateLayout={(layout, branchId) => this.updateLayout(layout, branchId)} />
                </CardBody>
            </Card>
        )
    }

    editBranch = (props) => {
        return (
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">CreateBranch</div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Branch List") }}>LeaderboardList</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <BranchAdd {...this.props} updateLayout={(layout, branchId) => this.updateLayout(layout, branchId)} branchId={this.state.branchId} />
                </CardBody>
            </Card>
        )
    }

    updateLayout = (layout, branchId = '') => {
        this.setState({ customerLayout: layout, branchId: branchId });
    }

    getCustomerComponent(layout) {

        var showLayout = '';

        switch (layout) {
            case "Branch List": showLayout = this.leaderboardList(this.props);
                break;
            case "Create Branch": showLayout = this.branchCreate(this.props);
                break;
            case "Edit Branch": showLayout = this.editBranch(this.props);
                break;
            default:
                showLayout = "";
        }

        return showLayout;

    }

    render() {
        let showLayout = this.getCustomerComponent(this.state.customerLayout)
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        {showLayout}
                    </div>
                </div>
            </div>
        )
    }
}

export default  PetHoc(Leaderboard)