
/* eslint-disable */
import axios from 'axios'
const BASE_URL = "https://petwalking.sofmen.com/";

const Api = axios.create({
    baseURL: BASE_URL
});

Api.interceptors.request.use(function (config) {
         config.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
         config.headers.sessionToken = `${localStorage.getItem('sessionToken') }`;
         config.headers.common['Content-Type'] = 'application/json';
    
        return config;
});

Api.interceptors.response.use((response) => {
    return response;
}, function (error) {
    if (error.response && error.response.status === 401) {
        console.log('unauthorized, logging out ...');
        localStorage.clear(); 
        window.location.href='/';
    }
    return Promise.reject(error.response);

});


export default Api
