import React, { Component } from 'react'
import { Row, Col, Input } from 'reactstrap'
import { Formik } from 'formik';
import * as Yup from 'yup';
import {petUpdateAction} from '../redux/actions/PetActions'
import { connect } from 'react-redux';

class PetUpdate extends Component {
    constructor(props){
        super(props)
        this.state={
            error:'',
            loading:false,
            success:''
        }
    }
    petValue=(val)=>{
        return val!==undefined && val!==null?val:''
    }
    UNSAFE_componentWillReceiveProps(nextProps,prevState){
        console.log(nextProps)
        if(nextProps.success!==this.state.success){
            this.setState({
                loading:false,
                success:nextProps.success
            },()=>{
                setTimeout(nextProps.toggleButton(),4000)
            })
        }
       
    }
    submitUpdatePet=(values)=>{
        let formData= new FormData()
        Object.keys(values).forEach(key=>{
            
            if(values[key]!==''){
                if(key==='mediaFile'){
                    let imagedata = document.querySelector('input[type="file"]').files[0];
                    formData.append(key, imagedata);
                }else{
                    formData.append(key, values[key]);
                }
                
            } 
        })
       
        this.setState({loading:true},()=>{
            this.props.petUpdateAction(this.props.petId,formData)
        })
    }
    render() {
        let pet = this.props.allPets.find(pt => pt.id === this.props.petId)
        
        return (
            <div className="justify-content-center">
                {this.state.success!=='' && <div className="alert alert-success">{this.state.success}</div>}
            <Formik
                enableReinitialize 
                initialValues={{ age: this.petValue(pet.age), breed: this.petValue(pet.breed),gender:this.petValue(pet.gender),weight:this.petValue(pet.weight),bodyType:this.petValue(pet.bodyType),color:this.petValue(pet.color),petName:this.petValue(pet.petName),mediaFile:'' }}
                onSubmit={this.submitUpdatePet}
                validationSchema={Yup.object().shape({ petName: Yup.string().required("Required") })}
            >
                {
                    props => {
                        const { errors, handleChange, handleSubmit, handleBlur, touched ,values} = props
                       
                        return (
                            <form onSubmit={handleSubmit} >
                                <Row>
                                    <Col lg="6" md="6">
                                        <div className={`form-group`}>
                                            <label className="col-md-4">Pet Name: </label>
                                            <div className="col-md-8"> 
                                                <Input 
                                                    name="petName" 
                                                    type="text" 
                                                    value={values.petName}
                                                    className={errors.petName && touched.petName ? "form-control error" : "form-control"} 
                                                    onChange={handleChange} 
                                                    onBlur={handleBlur}
                                                />
                                                {errors.petName && touched.petName && <div className="input-feedback">{errors.petName}</div>}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="6" md="6">
                                        <div className={`form-group`}>
                                            <label className="col-md-4">Age: </label>
                                            <div className="col-md-8"> 
                                                <Input 
                                                    name="age" 
                                                    type="number" 
                                                    value={values.age}
                                                    className={errors.age && touched.age ? "form-control error" : "form-control"} 
                                                    onChange={handleChange} 
                                                    onBlur={handleBlur}
                                                />
                                                {errors.age && touched.age && <div className="input-feedback">{errors.age}</div>}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6" md="6">
                                        <div className={`form-group`}>
                                            <label className="col-md-4">Breed: </label>
                                            <div className="col-md-8"> 
                                                <Input 
                                                    name="breed" 
                                                    type="text" 
                                                    value={values.breed}
                                                    className={errors.breed && touched.breed ? "form-control error" : "form-control"} 
                                                    onChange={handleChange} 
                                                    onBlur={handleBlur}
                                                />
                                                {errors.breed && touched.breed && <div className="input-feedback">{errors.breed}</div>}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="6" md="6">
                                        <div className={`form-group`}>
                                            <label className="col-md-4">Gender: </label>
                                            <div className="col-md-8"> 
                                                <Input 
                                                    name="gender" 
                                                    type="text" 
                                                    value={values.gender}
                                                    className={errors.gender && touched.gender ? "form-control error" : "form-control"} 
                                                    onChange={handleChange} 
                                                    onBlur={handleBlur}
                                                />
                                                {errors.gender && touched.gender && <div className="input-feedback">{errors.gender}</div>}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6" md="6">
                                        <div className={`form-group`}>
                                            <label className="col-md-4">Weight: </label>
                                            <div className="col-md-8"> 
                                                <Input 
                                                    name="weight" 
                                                    type="text" 
                                                    value={values.weight}
                                                    className={errors.weight && touched.weight ? "form-control error" : "form-control"} 
                                                    onChange={handleChange} 
                                                    onBlur={handleBlur}
                                                />
                                                {errors.weight && touched.weight && <div className="input-feedback">{errors.weight}</div>}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="6" md="6">
                                        <div className={`form-group`}>
                                            <label className="col-md-4">Body Type: </label>
                                            <div className="col-md-8"> 
                                                <Input 
                                                    name="bodyType" 
                                                    type="text" 
                                                    value={values.bodyType}
                                                    className={errors.bodyType && touched.bodyType ? "form-control error" : "form-control"} 
                                                    onChange={handleChange} 
                                                    onBlur={handleBlur}
                                                />
                                                {errors.bodyType && touched.bodyType && <div className="input-feedback">{errors.bodyType}</div>}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6" md="6">
                                        <div className={`form-group`}>
                                            <label className="col-md-4">Color: </label>
                                            <div className="col-md-8"> 
                                                <Input 
                                                    name="color" 
                                                    type="text" 
                                                    value={values.color}
                                                    className={errors.color && touched.color ? "form-control error" : "form-control"} 
                                                    onChange={handleChange} 
                                                    onBlur={handleBlur}
                                                />
                                                {errors.color && touched.color && <div className="input-feedback">{errors.color}</div>}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="6" md="6">
                                        <div className={`form-group`}>
                                            <label className="col-md-4">Image: </label>
                                            <div className="col-md-8"> 
                                                <Input 
                                                    name="mediaFile" 
                                                    type="file" 
                                                    className={errors.mediaFile && touched.mediaFile ? "form-control error" : "form-control"} 
                                                    onChange={handleChange} 
                                                    onBlur={handleBlur}
                                                />
                                                {errors.mediaFile && touched.mediaFile && <div className="input-feedback">{errors.mediaFile}</div>}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="justify-content-center">
                                {this.state.error !== '' && <p className="alert alert-danger mt-2 ">{this.state.error}</p>}
                                {this.state.loading?<button type="submit" className="btn btn-primary col-md-3 mt-2 w-100"><i className="fa fa-spinner fa-pulse fa-2x fa-fw text-center"></i></button>:<button type="submit" className="btn btn-primary col-md-3 mt-2 w-100">Update</button>}
                                </Row>
                            </form>
                        )
                    }
                }
            </Formik>
            </div>
        )
    }
}
const mapStateToProps =(state)=>{
    return {
        success: state.petReducer.petsuccess
    }
}
const mapDispatchToProps={
    petUpdateAction
}

export default connect(mapStateToProps,mapDispatchToProps)(PetUpdate)