export const LOGOUT = 'LOGOUT';
export const LOGIN = 'LOGIN';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const SET_PASSWORD = 'SET_PASSWORD';

export const PET_LIST = 'PET_LIST';
export const PET_UPDATE = 'PET_UPDATE';

export const PAGE_LIST = 'PAGE_LIST';
export const PAGE_CREATE = 'PAGE_CREATE';
export const PAGE_UPDATE = 'PAGE_UPDATE';
export const PAGE_REMOVE = 'PAGE_REMOVE';

export const REDIRECT_TO = 'REDIRECT_TO';
export const LOG_ERROR = 'LOG_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const USER_PROFILE = 'USER_PROFILE';
export const LOOKUP_DATA = 'LOOKUP_DATA';

export const CUSTOMER_LIST = 'CUSTOMER_LIST';
export const CUSTOMER_STATEMENT = 'CUSTOMER_STATEMENT';
export const CUSTOMER_CREATE = 'CUSTOMER_CREATE';
export const CUSTOMER_UPDATE = 'CUSTOMER_UPDATE';



export const ORDER_CREATE = 'ORDER_CREATE';
export const ORDER_UPDATE = 'ORDER_UPDATE';
export const ORDER_STATUS = 'ORDER_STATUS';
export const ORDER_DELETE = 'ORDER_DELETE';
export const DELIVER_ORDER = 'DELIVER_ORDER';

export const INVOICE_LIST = 'INVOICE_LIST';
export const INVOICE_CREATE = 'INVOICE_CREATE';
export const INVOICE_UPDATE = 'INVOICE_UPDATE';

export const TRANSFER_PACKAGE = 'TRANSFER_PACKAGE';
export const CANCEL_PACKAGE = 'CANCEL_PACKAGE';
export const ACCEPT_PACKAGE = 'ACCEPT_PACKAGE';
export const ASSIGN_PACKAGE = 'ASSIGN_PACKAGE';
export const INCOMING_PACKAGE_LIST = 'INCOMING_PACKAGE_LIST';
export const OUTING_PACKAGE_LIST = 'OUTING_PACKAGE_LIST';
export const ACCEPTED_PACKAGE_LIST = 'ACCEPTED_PACKAGE_LIST';

export const USER_LIST = 'USER_LIST';
export const USER_CREATE = 'USER_CREATE';
export const USER_UPDATE = 'USER_UPDATE';

export const BRANCH_LIST = 'BRANCH_LIST';
export const BRANCH_CREATE = 'BRANCH_CREATE';
export const BRANCH_UPDATE = 'BRANCH_UPDATE';

export const INVENTORY_REPORT = 'INVENTORY_REPORT';
export const WASTAGE_REPORT = 'WASTAGE_REPORT';
export const SALES_REPORT = 'SALES_REPORT';

export const ITEM_CREATE='ITEM_CREATE';
export const ITEM_UPDATE='ITEM_UPDATE';
export const ITEM_DELETE='ITEM_DELETE';
export const REGISTRATION_REPORT='REGISTRATION_REPORT';
export const APP_OPEN_REPORT='APP_OPEN_REPORT';
export const WALK_STAT_REPORT='WALK_STAT_REPORT';
