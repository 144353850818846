import React, {Component} from 'react'
import { Card, CardBody } from 'reactstrap'
import { connect } from 'react-redux';
import PageList from '../components/PageList'
import CreatePage from '../components/CreatePage'
import  {addPageAction,getPageList,updatePageAction} from  '../redux/actions/PageActions'

class Pages extends Component{

	constructor(props){ 
    super(props);

    this.state = {
        defaultLayout : "Page List",
        templateId:''
        }
    }
    pageCreate = () =>{
    	return(
    		<Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">{(' Page Details')}</div>
                        </div>
                        <div className="col-md-6">
                           <button className="btn btn-primary mt-2 float-right" onClick={()=>this.updateLayout('Page List')}>Page List</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <CreatePage updateLayout={this.updateLayout} templateId={this.state.templateId} {...this.props}/>
                </CardBody>
            </Card>
            )
    }
    pageList = () =>{
    	return(
    		<Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">{('Static Page List')}</div>
                        </div>
                        <div className="col-md-6">
                           <button className="btn btn-primary mt-2 float-right" onClick={()=>this.updateLayout('Page Create')}>Add Page</button>
                        </div>
                    </div>
                   
                </div>
                <CardBody>
                    <PageList updateLayout={this.updateLayout} {...this.props}/>
                </CardBody>
            </Card>
            )
    }

    

    updateLayout = (layout,templateId) =>{
        this.setState({defaultLayout : layout,templateId:templateId});
    }

    componentDidMount() {
        document.title="Page List"
        this.setState({loading:true},()=>{
            this.props.getPageList(); 
        })
    }
    getViewComponent(layout){

        switch(layout){
            case "Page List" : return this.pageList();
            case "Page Create" : return this.pageCreate();
            default: return null
        }

       
    }

	render(){
		let showLayout = this.getViewComponent(this.state.defaultLayout)
		return(
			<div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        {showLayout}
                    </div>
                </div>
            </div>
			)
	}
}
const mapStateToProps = state => {
    return {
        pageList: state.pageReducer.pageList,
        success: state.pageReducer.success,
        error: state.pageReducer.error,
    }
}
const mapDispatchToProps = {
    addPageAction,
    getPageList,
    updatePageAction
}
export default  connect(mapStateToProps, mapDispatchToProps)(Pages)